const defaultState = {
    isWarningModalOpen: false,
    firstNotifAboutSession: false,
    isInitAuth: false,
    userId: null,
    userFullName: null,
    userIsAdmin: null,
    token: null,
    startTime: null,
    startExtraTime: null,
}

export const LOG_OUT = "LOG_OUT"
export const LOG_IN = "LOG_IN"
export const MODAL_SESSION_OPEN = "MODAL_SESSION_OPEN"
export const INIT_AUTH = "INIT_AUTH"
export const NOTIF_ABOUT_SESSION = "NOTIF_ABOUT_SESSION"

const standartTime = 9900000;
// const standartTime = 15000;


export default function authReducer(state = defaultState, action) {
    switch (action.type) {
        case LOG_OUT:
            return state = defaultState
        case LOG_IN:
            return {
                ...state,
                userId: action.payload.user_ID,
                userFullName: action.payload.full_name,
                userIsAdmin: action.payload.isAdmin,
                token: action.payload.token,
                startTime: action.payload.startTime,
                startExtraTime: action.payload.startTime + standartTime,
                isInitAuth: true,
            }
        case MODAL_SESSION_OPEN:
            return {...state, isWarningModalOpen: action.payload}
        case NOTIF_ABOUT_SESSION:
            return {...state, firstNotifAboutSession: action.payload}
        case INIT_AUTH:
            return {...state, isInitAuth: action.payload}
        default:
            return state
    }
}

export const logIn = payload => ({type: LOG_IN, payload}) // actionCreator
export const modalSessionOpen = payload => ({type: MODAL_SESSION_OPEN, payload}) // actionCreator
export const NotifAboutSession = payload => ({type: NOTIF_ABOUT_SESSION, payload}) // actionCreator
export const initAuth = payload => ({type: INIT_AUTH, payload}) // actionCreator
export const logOut = () => ({type: LOG_OUT}) // actionCreator


