import {useAuth} from '../hook/useAuth'
import NotFoundPage from "../pages/NotFoundPage";
import React from "react";

const AdminAuth = ({children}) => {
    const {token, user} = useAuth();

    if (!token || !user.isAdmin) {
        return <NotFoundPage/>
    }

    return children

}

export {AdminAuth}