import React, {useEffect, useState} from 'react';
import {TextField} from "@mui/material";
import {Autocomplete} from "@mui/lab";
import {$authHost} from "../../http";
import Paper from "@mui/material/Paper";

const AutocompletePeriod = (props) => {

    const [openAutocomplete, setOpenAutocomplete] = React.useState(false);
    const loading = openAutocomplete;

    const [options, setOptions] = useState([]);

    useEffect(() => {
        let active = true;
        if (!loading) {
            return undefined;
        }
        (async () => {
            if (active) {
                await $authHost.get(`/form-budget-submission/combox/reporting-period`).then(res => {
                    const data = res.data;
                    setOptions(Object.values(data));
                });
            }
        })();
        return () => {
            active = false;
        };
    }, [loading]);

    useEffect( () =>{
        if (!openAutocomplete) {
            setOptions([]);
        }
    }, [openAutocomplete])


    return (
        <Autocomplete
            value={props.value}
            size="small"
            open={openAutocomplete}
            onOpen={() => {
                setOpenAutocomplete(true);
            }}
            onClose={() => {
                setOpenAutocomplete(false);
            }}
            name="organization"
            id="organization"
            options={options}
            sx={{ width: '100%', minHeight: '32px', maxHeight: '32px', paddingTop: '4px'}}
            isOptionEqualToValue={(option, value) => option === value}
            getOptionLabel={(option) => option}
            loading={loading}
            onBlur={()=>{
                props.onBlur()}}
            noOptionsText='Нет доступных вариантов'
            onChange={(event, newValue) => {
                props.onChange(newValue);
            }}
            PaperComponent={({ children }) => (
                <Paper style={{padding: '0', width: 'max-content' }}>{children}</Paper>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    size="small"
                    InputProps={{
                        sx:{color: '#555e68', fontSize: '14px'},
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
            renderOption={(props, option) => (
                <li {...props} style={{color: '#555e68', fontSize: '14px',
                    margin: '1px',
                }}>{option}</li>
            )}
        />
    );
};

export default AutocompletePeriod;