import {call, put, takeEvery} from "redux-saga/effects"
import {changeDatasource, GET_DATASOURCE} from "../reducers/budgetsAdvancesTableReducer";
import {$authHost} from "../http";

// const getDatasourceFromApi = ({skip, limit, sortInfo, groupBy, filterValue}) => $authHost.get('/form-budget-submission/?limit=' + limit + '&skip=' + skip + (groupBy && groupBy.length ? '&groupBy=' + groupBy : '') + '&sortInfo=' + JSON.stringify(sortInfo) + '&filterBy=' + JSON.stringify(filterValue))


function* getDatasourceWorker(action) {
    const {skip, limit, sortInfo, groupBy, filterValue} = action.payload
    // const skip = action.payload.skip
    // const limit = action.payload.limit
    // const sortInfo = action.payload.sortInfo
    // const groupBy = action.payload.groupBy
    // const filterValue = action.payload.filterValue
    const url = '/form-budget-submission/?limit=' + (limit ? limit : 10) + '&skip=' + (skip ? skip : 0) +
        (groupBy && groupBy.length ? '&group_by=' + groupBy : '&group_by=0') +
        (sortInfo ? '&sort_info=' + JSON.stringify(sortInfo) : '&sort_info=' + JSON.stringify({id: ''}))
        + (filterValue ? '&filter_by=' + JSON.stringify(filterValue) : '&filter_by=' + JSON.stringify({id: ''}))
    // console.log(url)
    const response = yield call($authHost.get, url)
    const totalCount = response.headers.get('X-Total-Count');
    const data = yield response.data
    yield put(changeDatasource({data, count: parseInt(totalCount)}))
}

export function* datasourceWatcher() {
    yield takeEvery(GET_DATASOURCE, getDatasourceWorker)
}
