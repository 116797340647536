import React, {useEffect, useState} from 'react';
import Autocomplete from "@mui/material/Autocomplete";
import {TextField} from "@mui/material";
import {$authHost} from "../../http";
import {createTheme} from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const AutocompleteComponent = (props) => {

    const [openAutocomplete, setOpenAutocomplete] = React.useState(false);
    const loading = openAutocomplete;

    const [options, setOptions] = useState([]);
    const search = async (field, x) => {
        try {
            switch (field){
                case "org":
                    await $authHost.get(`/form-budget-submission/combox/organization?organization=${x}&contract_number_gp=${[]}}&dc_number_contract_gp=${[]}`).then(res => {
                        const data = res.data;
                        setOptions(data);
                    });
                    break;
                case "loc":
                    await $authHost.get(`/form-budget-submission/combox/location?location=${x}&object_code=${props.pair_value ? props.pair_value : []}`).then(res => {
                        const data = res.data;
                        setOptions(data);
                    });
                    break;
                case "code":
                    await $authHost.get(`/form-budget-submission/combox/object-code?object_code=${x}&location=${[]}`).then(res => {
                        const data = res.data;
                        setOptions(data);
                    });
                    break;
                case "sub":
                    await $authHost.get(`/form-budget-submission/combox/subcontractor?subcontractor=${x}&contract_number=${[]}`).then(res => {
                        const data = res.data;
                        setOptions(data);
                    });
                    break;
                case "contract":
                    await $authHost.get(`/form-budget-submission/combox/contract-number?contract_number=${x}&subcontractor=${[]}`).then(res => {
                        const data = res.data;
                        setOptions(data);
                    });
                    break;
                case "gp":
                    await $authHost.get(`/form-budget-submission/combox/contract-number-gp?contract_number_gp=${x}&organization=${[]}`).then(res => {
                        const data = res.data;
                        setOptions(data);
                    });
                    break
                case "dc":
                    await $authHost.get(`/form-budget-submission/combox/dc-contract-number-gp?dc_number_contract_gp=${x}&organization=${[]}`).then(res => {
                        const data = res.data;
                        setOptions(data);
                    });
                    break;
                case "work":
                    await $authHost.get(`/form-budget-submission/combox/work?work=${x}&organization=${[]}`).then(res => {
                        const data = res.data;
                        setOptions(data);
                    });
                    break;
                default: break;
            }
        } catch (e) {
        }
    }

    useEffect( () =>{
        if (!openAutocomplete) {
            setOptions([]);
        }
    }, [openAutocomplete])


    useEffect(() => {
        let active = true;
        if (!loading) {
            return undefined;
        }
        (async () => {
            if (active) {
                try {
                    switch (props.field) {
                        case "org":
                            await $authHost.get(`/form-budget-submission/combox/organization?organization=${[]}&contract_number_gp=${[]}}&dc_number_contract_gp=${[]}`).then(res => {
                                const data = res.data;
                                setOptions(data);
                            });
                            break;
                        case "loc":
                            await $authHost.get(`/form-budget-submission/combox/location?location=${[]}&object_code=${props.pair_value ? props.pair_value : []}`).then(res => {
                                const data = res.data;
                                setOptions(data);
                            });
                            break;
                        default: break;
                    }
                } catch (e) {
                }
            }
        })();
        return () => {
            active = false;
        };
    }, [loading]);


    return (
        <Autocomplete

            value={props.value}
            size="small"
            open={openAutocomplete}
            onOpen={() => {
                setOpenAutocomplete(true);
            }}
            onClose={() => {
                setOpenAutocomplete(false);
            }}
            name="organization"
            id="organization"
            options={options}
            sx={{ width: '100%', minHeight: '32px', maxHeight: '32px', paddingTop: '4px'}}
            isOptionEqualToValue={(option, value) => option === value}
            getOptionLabel={(option) => option}
            loading={loading}
            onBlur={()=>{
                props.onBlur()}}
            noOptionsText='Нет доступных вариантов'
            onChange={(event, newValue) => {
                props.onChange(newValue);
            }}
            PaperComponent={({ children }) => (
                <Paper style={{padding: '0', width: 'max-content' }}>{children}</Paper>
            )}
            onInputChange={(event, newInputValue) => search(props.field, newInputValue)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name="label"
                    size="small"
                    variant="standard"
                    placeholder={props.placeholder}
                    InputProps={{
                        sx:{color: '#555e68', fontSize: '14px'},
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
            renderOption={(props, option) => (
                <li {...props} style={{color: '#555e68', fontSize: '14px',
                    margin: '1px',
                }}>{option}</li>
            )}
        />
    );
};

export default AutocompleteComponent;