import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import FileUpload from "./FileUpload";
import StatusComponent from "./Status_component";
import ProgressComponent from "./ProgressComponent";
import {KillTask} from "../../reducers/uuidReducer";

const ProcessComponent = ({application_id}) => {

    const dispatch = useDispatch()
    const tasks = useSelector(state => state.uuidReducer.tasks)
    const local_tasks = tasks.filter(el => el.application_id === application_id);


    return (
        <>
            {local_tasks.length > 0 &&
                <>
                    {local_tasks.map((task, key) =>{
                        return(
                            <>
                                <div key={key}>
                                    <ProgressComponent
                                        uuid={task.uuid}
                                        status={task.status}
                                        progress={task.progress}
                                    />
                                </div>
                            </>
                        )
                    })}
                </>
            }
        </>
    );
};

export default ProcessComponent;