import React, {createContext, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {initAuth, logIn, logOut, modalSessionOpen, NotifAboutSession} from "../reducers/authReducer";
import {useNavigate} from "react-router-dom";
import {TimeoutWarningModal} from "../components/modals/timeoutWarningModal";

export const AuthContext = createContext(null);

export const AuthProvider = ({children}) => {

    const navigate = useNavigate()

    const token = useSelector(state => state.authReducer.token)
    const user = {
        user_ID: useSelector(state => state.authReducer.userId),
        full_name: useSelector(state => state.authReducer.userFullName),
        isAdmin: useSelector(state => state.authReducer.userIsAdmin),
        startTime: useSelector(state => state.authReducer.startTime)
    }
    const dispatch = useDispatch()

    const isWarningModalOpen = useSelector(state => state.authReducer.isWarningModalOpen)
    const notifAboutSession = useSelector(state => state.authReducer.firstNotifAboutSession)
    const startExtraTime = useSelector(state => state.authReducer.startExtraTime)
    const isInitAuth = useSelector(state => state.authReducer.isInitAuth)

    const signIn = (user_ID, full_name, isAdmin, token) => {
        var now = new Date().getTime();
        dispatch(logIn({
            user_ID: user_ID,
            full_name: full_name,
            isAdmin: isAdmin,
            token: token,
            startTime: now,
        }));
        dispatch(initAuth(true));
    }


    const signOut = (cb) => {
        dispatch(logOut())
        localStorage.clear(); //FIXME убрать когда откажемся от localStorage
        cb();
    }


    useEffect(() => {
        const standartTime = 9900000;
        var _time = 9900000;
        const extraTime = 900000;
        var _extratime = 900000;

        // const standartTime = 15000;
        // var _time = 15000;
        // const extraTime = 15000;
        // var _extratime = 15000;

        const createTimeout1 = () => setTimeout(()=>{
            if(isWarningModalOpen === false){
                dispatch(modalSessionOpen(true));
                dispatch(NotifAboutSession(true));
            }
        }, _time);

        const createTimeout2 = () => setTimeout(() => {
            signOut(()=>{ navigate('/')})
        }, _extratime);


        // Initialization
        if(user.startTime !== null) {
            let now = new Date().getTime();
            _time = standartTime - (now - user.startTime);
            _extratime = extraTime - (now - startExtraTime);
            if(_time < 0 && _extratime <= 0){
                signOut(()=>{ navigate('/')})
            } else if (notifAboutSession) {
                createTimeout2();
            } else {
                createTimeout1();
            }
        }
        return () => {
        }
    },[isWarningModalOpen, isInitAuth])

    const value = {user, token, signIn, signOut}

    return (
        <AuthContext.Provider value={value}>
            {children}

            {isWarningModalOpen &&
            <TimeoutWarningModal
                isOpen={isWarningModalOpen}
                onRequestClose={()=>{dispatch(modalSessionOpen(false))}}/>
            }
        </AuthContext.Provider>
    )
}