import {LOG_OUT} from "./authReducer";
import dayjs from "dayjs";
import {createSelector} from "reselect";

const defaultState = {
    dataSource: {
        data: [],
        count: 0,
        loading: false
    },
    selectedRows: {},
    performance: false,
    filter: false,
    // searchDate: dayjs(),
    someEditingNow: null,
    searchText: '',
    visibleColumns: [
        // {id: 'id', name: 'id', header: 'ID', type: 'number', defaultWidth: 50, defaultFlex: 1,},
        // {id: 'date', name: 'date', header: 'Дата', defaultFlex: 1,},
        // {id: 'case', name: 'case', header: 'Сценарий', defaultWidth: 100, defaultFlex: 1,},
        // {id: 'organization', name: 'organization', header: 'Организация', defaultFlex: 1,},
        // {id: 'location', name: 'location', header: 'Локация', group: 'object', defaultFlex: 1,},
        // {id: 'object_code', name: 'object_code', header: 'Код объекта', group: 'object', defaultFlex: 1,},
        // {id: 'subcontractor', name: 'subcontractor', header: 'Контрагент/Субподрядчик', minWidth: 150, defaultFlex: 1,},
        // {id: 'contract_number', name: 'contract_number', header: '№ Договора', group: 'contracts', defaultFlex: 1,},
        // {
        //     id: 'contract_number_gp',
        //     name: 'contract_number_gp',
        //     header: '№ Договора ГП',
        //     group: 'contracts',
        //     defaultFlex: 1,
        // },
        // {
        //     id: 'dc_number_contract_gp',
        //     name: 'dc_number_contract_gp',
        //     header: 'ДС к Договору ГП',
        //     group: 'contracts',
        //     defaultFlex: 1,
        // },
        // {id: 'work', name: 'work', header: 'Наименование работ', defaultFlex: 1,},
        // {id: 'amount', name: 'amount', header: 'Сумма аванса/выполнения', defaultFlex: 1,},
        // {id: 'amount_adjustment', name: 'amount_adjustment', header: 'Сумма аванса/выполнения', defaultFlex: 1,},
        // {
        //     id: 'reporting_period',
        //     name: 'reporting_period',
        //     header: 'дата отчетного периода',
        //     group: 'information',
        //     defaultFlex: 1,
        // },
        // {id: 'note', name: 'note', header: 'Примечание', group: 'information', defaultFlex: 1,},
        // {id: 'status', name: 'status', header: 'Статус', group: 'information', defaultFlex: 1,},
        // {id: 'options', name: 'options', header: '', defaultFlex: 1,}
    ],
    showColumns: ['date', 'executor', 'case', 'organization', 'location', 'object_code', 'subcontractor', 'contract_number', 'id',
        'contract_number_gp', 'dc_number_contract_gp', 'work', 'amount_proposed', 'amount_accepted', 'reporting_period', 'note', 'status', 'options'],
    anchorMenu: false,
    skip: 1,
    limit: 20,
    skip_count: 0,
    deleteFilter: false,
    errorValid: false
}

export const CHANGE_DATASOURCE = "CHANGE_DATASOURCE"
export const GET_DATASOURCE = "GET_DATASOURCE"
export const SELECT_ROWS = "SELECT_ROWS"
export const UPDATE_VALUE = "UPDATE_VALUE"
export const UPDATE_CHECKBOX = "UPDATE_CHECKBOX"
export const SET_SEARCH_DATE = "SET_SEARCH_DATE"
export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT"
export const SET_VISIBLE_COLUMNS = "SET_VISIBLE_COLUMNS"
export const UPDATE_VISIBLE_COLUMNS = "UPDATE_VISIBLE_COLUMNS"
export const HIDE_COLUMN = "HIDE_COLUMN"
export const SHOW_COLUMN = "SHOW_COLUMN"
export const DEFAULT_COLUMN = "DEFAULT_COLUMN"
export const SET_ANCHOR_MENU = "SET_ANCHOR_MENU"
export const SET_LIMIT = "SET_LIMIT"
// export const SET_SKIP = "SET_SKIP"
// export const SET_SKIP_COUNT = "SET_SKIP_COUNT"
export const SET_ACTUAL_PAGE = "SET_ACTUAL_PAGE"
export const SET_ROWS_PAGES = "SET_ROWS_PAGES"
export const DEL_FILTER = "DEL_FILTER"
export const ERROR_VALID = "ERROR_VALID"
export const CHANGE_EDITING_NOW = "CHANGE_EDITING_NOW"


export default function budgetsAdvancesTableReducer(state = defaultState, action) {
    switch (action.type) {
        case LOG_OUT:
            return state = defaultState
        case CHANGE_DATASOURCE:
            return {...state, dataSource: action.payload}
        case SELECT_ROWS:
            return {...state, selectedRows: action.payload}
        case SET_SEARCH_DATE:
            return {...state, searchDate: action.payload}
        case SET_SEARCH_TEXT:
            return {...state, searchText: action.payload}
        case SET_VISIBLE_COLUMNS:
            return {...state, visibleColumns: action.payload}
        case UPDATE_VISIBLE_COLUMNS:
            const updatedColumns = state.visibleColumns;
            updatedColumns[action.payload.index].visible = action.payload.isVisible;
            return {...state, visibleColumns: updatedColumns}
        case HIDE_COLUMN:
            return {...state, showColumns: state.showColumns.filter(c => c !== action.payload),};
        case SHOW_COLUMN:
            return {...state, showColumns: [...state.showColumns, action.payload],};
        case DEFAULT_COLUMN:
            return {...state, showColumns: defaultState.showColumns,};
        case SET_ANCHOR_MENU:
            return {...state, anchorMenu: action.payload,};
        case UPDATE_VALUE:
            return {
                ...state,
                dataSource: {
                    ...state.dataSource,
                    data: state.dataSource.data.map((row, rowIndex) => {
                        if (rowIndex === action.payload.rowId) {
                            return {
                                ...row,
                                [action.payload.columnId]: action.payload.value,
                            };
                        }
                        return row;
                    }),
                },
            };
        case UPDATE_CHECKBOX:
            return {...state, [action.payload.name]: !state[action.payload.name]}
        case SET_LIMIT:
            let new_pagesCount = Math.ceil(state.rows/action.payload);
            return {...state, limit: action.payload, pages: new_pagesCount, skip: defaultState.skip, skip_count: defaultState.skip_count}
        case SET_ACTUAL_PAGE:
            return {...state, skip: action.payload, skip_count: (action.payload - 1) * state.limit}
        case SET_ROWS_PAGES:
            return {...state, rows: action.payload, pages: Math.ceil(action.payload/state.limit)}
        case DEL_FILTER:
            return {...state, deleteFilter: action.payload}
        case ERROR_VALID:
            return {...state, errorValid: action.payload}
        case CHANGE_EDITING_NOW:
            return {...state, someEditingNow: action.payload}
        default:
            return state
    }
}

 // actionCreator
export const changeDatasource = payload => ({type: CHANGE_DATASOURCE, payload})
export const getDatasource = payload => ({type: GET_DATASOURCE, payload})
export const updateValue = payload => ({type: UPDATE_VALUE, payload})
export const selectRows = payload => ({type: SELECT_ROWS, payload})
export const updateCheckbox = payload => ({type: UPDATE_CHECKBOX, payload})
export const setSearchDate = payload => ({type: SET_SEARCH_DATE, payload})
export const setSearchText = payload => ({type: SET_SEARCH_TEXT, payload})
export const setVisibleColumns = payload => ({type: SET_VISIBLE_COLUMNS, payload})
export const updateVisibleColumns = payload => ({type: UPDATE_VISIBLE_COLUMNS, payload})
export const showColumn = payload => ({type: SHOW_COLUMN, payload})
export const hideColumn = payload => ({type: HIDE_COLUMN, payload})
export const defaultColumn = payload => ({type: DEFAULT_COLUMN})
export const setAnchorMenu = payload => ({type: SET_ANCHOR_MENU, payload})
export const setLimit = payload => ({type: SET_LIMIT, payload})
// export const setSkip = payload => ({type: SET_SKIP, payload})
// export const setSkipCount = payload => ({type: SET_SKIP_COUNT, payload})
export const setActualPage = payload => ({type: SET_ACTUAL_PAGE, payload})
export const setRowsPages = payload => ({type: SET_ROWS_PAGES, payload})
export const setDelFilter = payload => ({type: DEL_FILTER, payload})
export const setErorValid = payload => ({type: ERROR_VALID, payload})
export const setEditingNow = payload => ({type: CHANGE_EDITING_NOW, payload})
// export const selectDataAndCount = createSelector(
//     state.dataSource,
//     (dataSource) => ({
//         data: state.dataSource.data,
//         count: state.dataSource.count,
//     })
// );

