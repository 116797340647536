import {combineReducers} from "redux";
import authReducer from "./authReducer";
import uuidReducer from "./uuidReducer";
import {FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE,} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import {configureStore} from "@reduxjs/toolkit";
import budgetsAdvancesTableReducer from "./budgetsAdvancesTableReducer";
import createSagaMiddleware from "redux-saga";
import {rootWatcher} from "../saga";


const sagaMiddleware = createSagaMiddleware()

const rootReducer = combineReducers({
    authReducer,
    uuidReducer,
    budgetsAdvancesTableReducer,
})

const persistConfig = {
    key: 'root',
    storage, // ~ storage: storage
	// blacklist: ['budgetsAdvancesTableReducer', 'authReducer']
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(sagaMiddleware),
});

export const persistor = persistStore(store)
sagaMiddleware.run(rootWatcher)

