import React from 'react';

import {useDispatch, useSelector} from "react-redux";
import {setSearchDate, setSearchText} from "../reducers/budgetsAdvancesTableReducer";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import SearchIcon from '@mui/icons-material/Search';


function SearchComponent({defaultDataSource,p_setMyDataSource,  gridRef}) {
    const searchText = useSelector(state => state.budgetsAdvancesTableReducer.searchText)
    const dispatch = useDispatch()

    const handleChange = (newValue) => {
        dispatch(setSearchDate(newValue))
    };


    const onSearchChange = ({ target: { value } }) => {
        const visibleColumns = gridRef.current.visibleColumns;

        const lowerSearchText = value && value.toLowerCase();
        const newData = defaultDataSource.filter((p) => {
            return visibleColumns.reduce((acc, col) => {
                const v = (p[col.id] + '').toLowerCase(); // get string value
                return acc || v.indexOf(lowerSearchText) != -1; // make the search case insensitive
            }, false);
        });

        dispatch(setSearchText(value));
        p_setMyDataSource(newData);
    };

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField id = "searchText" name = "searchText"
                           label="Поиск"
                           type="text"
                           variant="standard"
                           value={searchText}
                           onChange={onSearchChange}
                           size="small" sx={{width: '250px' }} />
            </Box>

        </>
    );
}

export default SearchComponent;