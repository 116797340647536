import React from 'react';
import { NumericFormat } from 'react-number-format';
import { TextField } from '@mui/material';

const PagesCountsComponent = (props) => {

    const materialUITextFieldProps = {
        variant: "standard",
        size: 'small',
        type: 'number'
    };

    return (
        <NumericFormat
            value={props.value}
            onValueChange={(values) => {
                props.onChange(values.floatValue);
            }}
            allowLeadingZeros={false}
            isAllowed={(values) => {
                const { floatValue } = values;
                return floatValue <= props.pages && floatValue >= 1;
            }}
            allowNegative={false}
            customInput={TextField}
            {...materialUITextFieldProps}
            InputProps={{
                sx:{ width: '50px', fontSize: '14px'}}}
        />
    )
}

export default PagesCountsComponent;