import React from 'react';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import dayjs from "dayjs";
import {$authHost} from "../../http";
import {useDispatch, useSelector} from "react-redux";
import {addTask, UpdateStatus} from "../../reducers/uuidReducer";
import ProcessComponent from "../ProcessComponents/ProcessComponent";
import {baseUrl} from "../baseRoute";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {TextField} from "@mui/material";
import {EventSourcePolyfill} from "event-source-polyfill";


const Sells_revenue = () => {

    const dispatch = useDispatch()
    const token = useSelector(state => state.authReducer.token);

    //ограничения дат
    const maxDate = dayjs();

    const [dateOne, setDateOne] = React.useState(dayjs("03.03.2023"));

    const newTask = (payload) => {
        dispatch(addTask(payload));
    }

    const updateStatusTask = (payload) => {
        dispatch(UpdateStatus(payload));
    }

    const handleOneClick = async () => {
        var formatDate = dateOne.format('DD.MM.YYYY');
        try {
            await $authHost.get(`/sells-revenue-plan?date=${formatDate}`)
                .then(response => {
                    StatusTask(response.data, 2);
                });
        } catch (e) {
        } finally {

        }
    }

    function StatusTask(uuid_local, application){

        newTask({uuid: uuid_local, status: "PENDING", application_id: application, progress: 0});
        var EventSource = EventSourcePolyfill;

        const evtSource = new EventSource(`${baseUrl}task-status-stream?task_id=${uuid_local}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })

        evtSource.addEventListener("update", function (event){
            var _sendData = JSON.parse(event.data);
            updateStatusTask({task_id: uuid_local, status: _sendData.status, application_id: application, progress: _sendData.progress})
        });
        evtSource.addEventListener("end", function (event){
            updateStatusTask({task_id: uuid_local, status: "FINISHED", application_id: application, progress: 100})
            evtSource.close();
        });
        evtSource.addEventListener("failed", function (event){
            updateStatusTask({task_id: uuid_local, status: "FAILURE", application_id: application, progress: 0})
            evtSource.close();
        });

    }



    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom sx={{mb: 1}}>
                    План продаж и поступлений
                </Typography>
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <Grid item xs={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            views={['year', 'month', 'day']}
                            label="Дата"
                            sx={{width: '90%'}}
                            value={dateOne}
                            onChange={(newValue) => {
                                setDateOne(newValue);
                            }}
                            renderInput={(params) => <TextField sx={{width: '90%'}} {...params} />}
                            maxDate={maxDate}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{mt: 2}}
                id="2"
            >
                <Grid item xs={4}>
                    <LoadingButton
                        sx={{width: '90%'}}
                        onClick={handleOneClick}
                        endIcon={<SendIcon/>}
                        loadingPosition="end"
                        variant="contained"
                        color="company_gray"
                    >
                        Выгрузить
                    </LoadingButton>
                </Grid>
                <Grid item xs={12} id="2_process">
                    <ProcessComponent application_id={2}/>
                </Grid>


            </Grid>


        </>
    );
}

export default Sells_revenue