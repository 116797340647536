import React from 'react';
import {Box, CssBaseline} from "@mui/material";
import NavBar from "../components/NavBar";

function UserManagementPage() {
    // страница управления пользователями
    return (
        <>
            <CssBaseline/>
            <NavBar/>
            <Box sx={{display: 'flex', pt: '4%'}}>
                user management page
            </Box>
        </>
    );
}

export default UserManagementPage;