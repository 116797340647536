import React from 'react';
import {KillTask} from "../../reducers/uuidReducer";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import {Chip, Tooltip} from "@mui/material";
import Grid from "@mui/material/Grid";
import {baseUrl} from "../baseRoute";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {useDispatch} from "react-redux";
import {$authHost} from "../../http";

const ProgressComponent = ({uuid, status, progress}) => {

    const dispatch = useDispatch()

    const adios = (task_id) =>{
        dispatch(KillTask(task_id));
    }

    switch(status){
        case "PENDING":
            return(
                <>
                    <p style={{color: "grey"}}>Запрос в очереди...</p>
                    <LinearProgress color="inherit"/>
                </>
            );
        case "STARTED":
            return(
                <>
                    <p style={{color: "blue"}}>Выполнение запроса</p>
                    <LinearProgress variant="determinate" value={progress}/>
                </>
            );
        case "SUCCESS":
            return(
                <>
                    <p style={{color: "blue"}}>Подготовка к скачиванию</p>
                    <LinearProgress variant="determinate" value="100"/>
                </>
            );
        case "FAILURE":
            return(
                <p>
                    <Chip
                        variant="outlined"
                        color="error"
                        onClick={()=>adios(uuid)}
                        onDelete={()=>adios(uuid)}
                        deleteIcon={
                            <Tooltip title="очистить">
                                <CloseIcon/>
                            </Tooltip>
                        }
                        label="Ошибка загрузки, попробуйте еще раз" />
                </p>
            );
        case "FINISHED":
            return(
                <>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={3}
                        sx={{mt: 1}}
                    >
                        <Grid item xs="auto">
                            <Chip color="success"
                                  label={<a style={{color:"white"}} href={baseUrl+`excel-download?task_id=${uuid}`}> Скачать файл</a>}
                                  onDelete={()=>adios(uuid)}
                                  deleteIcon={
                                      <Tooltip title="очистить">
                                          <CloseIcon/>
                                      </Tooltip>
                                  }
                                  icon={<FileDownloadRoundedIcon />} />
                        </Grid>
                        <Grid item xs={5} sx={{height: '100%'}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <DoneAllIcon color="success" sx={{mr:1}}/>
                                <span style={{color: '#545454'}}>
                                    Отчет создан и готов к скачиванию
                                </span>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            );
        default:
            break;

    }

};

export default ProgressComponent;