import React from 'react';
import NavBar from "../components/NavBar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CssBaseline, Paper, } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Payment_form from "../components/export_components/Payment_form";
import Sells_revenue from "../components/export_components/Sells_revenue_form";
import Payment_plan from "../components/export_components/Payment_plan_form";
import Project_budjet from "../components/export_components/Project_budjet_form";


const reports = [
    'Движения по р/с', 
    // 'Заявки на оплату', // Временно скрыли Заявки на оплату
    'План продаж и поступлений', 
    'Бюджет по проектам',
]

const ExcelReportPage = () => {

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <NavBar />
                <Drawer
                    variant="permanent"
                    sx={{
                        width: '25%',
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { width: '25%', boxSizing: 'border-box' },
                        zIndex: "20"
                    }}
                >
                    <Toolbar />
                    <Box sx={{ overflow: 'auto' }}>
                        <List>
                            {reports.map((text, index) => (
                                <ListItem key={text} disablePadding>
                                    <ListItemButton href={`#${index}`}>
                                        <ListItemText primary={text} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>

                    </Box>
                </Drawer>
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <Toolbar />
                    <Typography variant="h4" gutterBottom sx={{ mb: 3 }} id="0">
                        Выгрузка отчетности
                    </Typography>
                    <Paper sx={{ p: 2, mb: 3 }}>
                        <Payment_plan />
                    </Paper>
                    {/* <Paper sx={{ p: 2, mb: 3 }}> // Временно скрыли Заявки на оплату
                        <Payment_form />
                    </Paper> */}
                    <Paper sx={{ p: 2, mb: 3 }}>
                        <Sells_revenue />
                    </Paper>
                    <Paper sx={{ p: 2, mb: 3 }}>
                        <Project_budjet />
                    </Paper>

                    <Box sx={{ height: '80vh' }} />
                </Box>
            </Box>

        </>


    );
};

export default ExcelReportPage;