import axios from "axios";
import {baseUrl} from "../components/baseRoute";
import {store} from "../reducers";



const $host = axios.create({
    baseURL: baseUrl,
    headers:{"Access-Control-Allow-Origin": "*"
    }
})

const $authHost = axios.create({
    baseURL: baseUrl,
    headers:{"Access-Control-Allow-Origin": "*"
    }
})


const authInterceptor = store => config => {
    var data = store.getState().authReducer.token;
    config.headers.authorization = `Bearer ${data}`;
    return config
}
$authHost.interceptors.request.use(authInterceptor(store))

export {
    $host,
    $authHost
}