import React from 'react';
import {useAuth} from "../hook/useAuth";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Image from 'mui-image';
import $ from "jquery";
import {useNavigate} from "react-router-dom";
import {Box, Button, Divider, Stack} from "@mui/material";
import BlurOnIcon from '@mui/icons-material/BlurOn';

const NavBar = () => {
    const {user, signOut} = useAuth();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState(null);

   // var location = window.location;
    const [menu, setMenu] = React.useState(window.location.pathname);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const exitHandler = () => {
        // var data = JSON.parse(localStorage.getItem('changes'));
        // var changes = localStorage.getItem('changes_m');
        // var now_change = Number(data);
        // if(now_change === 0 && changes !== 'true'){
        signOut(() => {
            navigate('/')
        });
        // }else {
        //     setSaveModalOpen(true);
        // }
    }

    return (
        <>
            <AppBar position="fixed" sx={{
                backgroundColor: '#171717',
                zIndex: '50'
            }}>
                <Toolbar style={{display: 'flex', justifyContent: 'space-between', alignContent: 'flex-start', alignItems: 'center'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <BlurOnIcon sx={{mr: 2}} />
                        {/*<Image src="images/main-logo.svg" width="40px" sx={{mr: 3}}/>*/}
                        <Typography variant="h6" xs={2} component="div">
                            ASPEX DEMO PORTAL
                        </Typography>

                        <Stack direction="row" spacing={1} id="navigation" alignItems="center" sx={{pl: 5}}>
                            {/*<Divider orientation="vertical" variant="middle" flexItem/>*/}
                            <Button
                                onClick={() => navigate('/excel_report')}  variant="contained"
                                disableElevation
                                color={menu === '/excel_report'? 'navigation_light': 'navigation_yellow'}
                                id="navigation_report"
                            >
                                <Typography variant="h7">
                                    Выгрузка отчетности
                                </Typography>
                            </Button>
                            <Divider orientation="vertical" variant="middle" flexItem/>
                            <Button
                                onClick={() => navigate('/budgets_and_advances')} variant="contained"
                                disableElevation
                                color={menu === '/budgets_and_advances'? 'navigation_light': 'navigation_yellow'}
                                id="budgets_and_advances"
                            >
                                <Typography variant="h7" className="active">
                                    Форма подачи бюджета
                                </Typography>
                            </Button>
                            {/*<Divider orientation="vertical" variant="middle" flexItem/>*/}
                        </Stack>
                    </div>

                    <div>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <Typography variant="h6" component="div" sx={{flexGrow: 1, mr: 2}}>
                                {user && user.full_name}
                            </Typography>
                            <AccountCircle/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            {/*<Box display={!!user.isAdmin ? "" : "none"}>*/}
                            {/*    <MenuItem onClick={() => navigate('/user_management')}>*/}
                            {/*        Управление пользователями*/}
                            {/*    </MenuItem>*/}
                            {/*</Box>*/}
                            <MenuItem onClick={exitHandler}>Выход</MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
        </>
    );
};

export default NavBar;