import React, {useCallback, useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import { TextField} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import dayjs from "dayjs";
import {$authHost} from "../../http";
import {baseUrl} from "../baseRoute";
import IconButton from "@mui/material/IconButton";
import {useDispatch, useSelector} from "react-redux";
import {addTask, UpdateStatus} from "../../reducers/uuidReducer";
import ProcessComponent from "../ProcessComponents/ProcessComponent";
import ClearIcon from '@mui/icons-material/Clear';
import {useAuth} from "../../hook/useAuth";
import { EventSourcePolyfill } from 'event-source-polyfill';

const Payment_plan = () => {
    const token = useSelector(state => state.authReducer.token);

    //тех стейты
    const [errorOrg, setErrorOrg] = React.useState(false);

    //ограничения дат
    const maxDate = dayjs();

    const dispatch = useDispatch()

    //стейты данных
    const [dateTwo, setDateTwo] = React.useState(dayjs("07.19.2022"));
    const [selectOrganisationTwo, setSelectOrganisationTwo] = React.useState(null);
    const [Organisations, setOrganisations] = React.useState([]);

    //запросы данных в бд
    const getOrganisations = useCallback(async ()=>{
        try{
            await $authHost.get(`/payment-plan-organization_name`).then(res => {
                const managers = res.data;
                setOrganisations(managers);
            })
        } catch (e){
        }finally {
        }
    }, [])

    const newTask = (payload) => {
        dispatch(addTask(payload));
    }

    const updateStatusTask = (payload) => {
        dispatch(UpdateStatus(payload));
    }

    const handleTwoClick = async () =>{
        if(!selectOrganisationTwo){
            setErrorOrg(true);
        }else{
            setErrorOrg(false);
            var formatDate = dateTwo.format('DD.MM.YYYY')
            try {
                await $authHost.get(`/payment-plan?date=${formatDate}&organization_name=${selectOrganisationTwo}`)
                    .then(response => {
                        // console.log("после запроса uuid=", response.data)
                        StatusTaskPaymentPlan(response.data, 5);
                    });
            } catch (e) {
            } finally {
            }
        }

    }


    function StatusTaskPaymentPlan(uuid_local, application){

        newTask({uuid: uuid_local, status: "PENDING", application_id: application, progress: 0});
        var EventSource = EventSourcePolyfill;
        const evtSource = new EventSource(`${baseUrl}task-status-stream?task_id=${uuid_local}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })

        evtSource.addEventListener("update", function (event) {
            var _sendData = JSON.parse(event.data);
            updateStatusTask({task_id: uuid_local, status: _sendData.status, application_id: application, progress: _sendData.progress})
        });
        evtSource.addEventListener("end", function (event) {
            updateStatusTask({task_id: uuid_local, status: "FINISHED", application_id: application, progress: 100})
            evtSource.close();
        });
        evtSource.addEventListener("failed", function (event) {
            updateStatusTask({task_id: uuid_local, status: "FAILURE", application_id: application, progress: 0})
            evtSource.close();
        });

    }

    const handleDeleteDate = () => {
        setDateTwo(dayjs());
    }

    useEffect( () =>{
        getOrganisations()
    }, [getOrganisations])


    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom sx={{mb:1}}>
                    Движения по р/с
                </Typography>
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <Grid item xs={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            views={['year', 'month', 'day']}
                            label="Дата"
                            sx={{width: '90%'}}
                            value={dateTwo}
                            onChange={(newValue) => {
                                setDateTwo(newValue);
                            }}
                            renderInput={(params) =>
                                (<TextField {...params} sx={{width: '90%'}} />)}
                            maxDate={maxDate}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        value={selectOrganisationTwo}
                        options={Organisations}
                        getOptionLabel={(option) => option}
                        sx={{width: '90%'}}
                        onChange={(event, newValue) => {
                                setSelectOrganisationTwo(newValue);
                            }
                        }
                        renderInput={(params) =>
                             <TextField {...params} label="Организация"  error={errorOrg}
                                               helperText={errorOrg ? "Выберите организацию" : false} />}

                    />
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{mt: 2}}
                id="1"
            >
                <Grid item xs={4}>
                    <LoadingButton
                        sx={{width: "90%"}}
                        onClick={handleTwoClick}
                        endIcon={<SendIcon/>}
                        variant="contained"
                        color="company_gray"
                    >
                        Выгрузить
                    </LoadingButton>
                </Grid>
                <Grid item xs={12} id="5_process">
                    <ProcessComponent application_id={5}/>
                </Grid>
                {/*<Grid item xs={12} sx={{mt: 2}}>*/}
                {/*    <HistoryComponent application_id={5}/>*/}
                {/*</Grid>*/}
            </Grid>
        </>
    );
}

export default Payment_plan;