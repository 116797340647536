import {Navigate} from 'react-router-dom'
import {useAuth} from '../hook/useAuth'

const RequareAuth = ({children}) => {
    const {token} = useAuth();

    if (!token) {
        return <Navigate to='/'/>
    }

    return children

}

export {RequareAuth}