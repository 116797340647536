import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    Checkbox,
    Chip,
    CssBaseline, FormControl,
    FormControlLabel,
    Menu,
    MenuItem, Select,
    Switch, TextField
} from "@mui/material";
import NavBar from "../components/NavBar";
import Typography from "@mui/material/Typography";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import '@inovua/reactdatagrid-community/index.css'
import SearchComponent from "../components/SearchComponent";
import { useDispatch, useSelector } from "react-redux";
import {
    hideColumn,
    selectRows,
    setAnchorMenu, setDelFilter,
    setLimit, setRowsPages, setActualPage,
    showColumn,
    updateCheckbox, setEditingNow
} from "../reducers/budgetsAdvancesTableReducer";
import Button from "@mui/material/Button";
import { $authHost } from "../http";
import FormAddRowComponent from "../components/forms/FormAddRowComponent";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import SelectEditor from "@inovua/reactdatagrid-community/SelectEditor";
import moment from 'moment';
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import Grid from "@mui/material/Grid";
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Paper from "@mui/material/Paper";
import ClearIcon from '@mui/icons-material/Clear';
import AutocompleteComponent from "../components/Autocompletes/AutocompleteComponent";
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useAuth } from "../hook/useAuth";
import AutocompleteObjectCode from "../components/Autocompletes/AutocompleteObjectCode";
import AutocompletePeriod from "../components/Autocompletes/AutocompletePeriod";
import ReplayIcon from '@mui/icons-material/Replay';
import CheckIcon from '@mui/icons-material/Check';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Divider from "@mui/material/Divider";
import NumberEditorComponent from "../components/numberCustomsFields/NumberEditorComponent";
import PagesCountsComponent from "../components/numberCustomsFields/PagesCountComponent";

window.moment = moment

function BudgetsAdvancesPage() {


    const { user } = useAuth();
    const dispatch = useDispatch()

    const selectedRows = useSelector(state => state.budgetsAdvancesTableReducer.selectedRows)
    const filter = useSelector(state => state.budgetsAdvancesTableReducer.filter)
    const deleteFilter = useSelector(state => state.budgetsAdvancesTableReducer.deleteFilter)

    const showColumns = useSelector(state => state.budgetsAdvancesTableReducer.showColumns)
    const anchorMenu = useSelector(state => state.budgetsAdvancesTableReducer.anchorMenu)
    const searchText = useSelector(state => state.budgetsAdvancesTableReducer.searchText)
    const userIsAdmin = useSelector(state => state.authReducer.userIsAdmin)


    //customPagination
    const skip = useSelector(state => state.budgetsAdvancesTableReducer.skip)
    const skip_count = useSelector(state => state.budgetsAdvancesTableReducer.skip_count)
    const limit = useSelector(state => state.budgetsAdvancesTableReducer.limit)
    const pages = useSelector(state => state.budgetsAdvancesTableReducer.pages)
    const rows = useSelector(state => state.budgetsAdvancesTableReducer.rows)


    const [someEditingNow, setSomeEditingNow] = useState(null);
    const [defaultDataSource, setDefaultDataSource] = useState([]);
    const [myDataSource, setMyDataSource] = useState([]);
    const [loading, setLoading] = useState(false);
    const [gridRef, setGridRef] = useState(null);
    const [gridDataRef, setDataGridRef] = useState(null);
    const [confirmation, setConfirmation] = useState({});
    const [buttonGroupAction, setButtonGroupAction] = useState(null);
    const [filterValue, setFilterValue] = useState('');
    const [computedData, setComputedData] = useState([]);
    const [organizationValue, setOrganizationValue] = useState('')
    const [locationValue, setLocationValue] = useState('')
    // const [caseValue, setCaseValue] = useState('')
    // const [statusValue, setStatusValue] = useState('')

    const searchTextRef = useRef(searchText);
    searchTextRef.current = searchText;
    const filterCellWrapperStyle = {
      height: '1000px'
    };

    const loadData = useCallback(() => {
        try {
            setLoading(true);
            const newDataSource = () => {
                return $authHost.get(userIsAdmin ? 'form-budget-submission/admin' : 'form-budget-submission/')
                    .then(response => {
                        return response.data;
                    })
            }
            newDataSource().then((data) => {
                setDefaultDataSource(data);
                setMyDataSource(data.filter(obj => obj.status !== 'Удалено'))
            });
        } catch (e) {
        } finally {
            setLoading(false);
        }
    }, []);

    const rowPatch = async (data, columnId, value) => {
        setLoading(true);
        const newData = { ...data };
        newData[columnId] = value;
        delete newData.status;
        delete newData.date;
        newData["user_id"] = Number(user.user_ID);
        try {
            // if (columnId === 'case' && value === 'Выполнение') {  // убрана возможность смены сценария
            //     if (!newData.contract_number || !newData.contract_number_gp || !newData.dc_number_contract_gp) {
            //         // alert('Для выбора сценария "выполнение" необходимо заполнить поля "Контрагент" и "Договора"')
            //         throw new Error('Для выбора сценария "выполнение" необходимо заполнить поля "Контрагент" и "Договора"')
            //     }
            // } else 
            if (newData.case === "Выполнение" && !(!!newData.subcontractor && !!newData.contract_number && !!newData.contract_number_gp && !!newData.dc_number_contract_gp)) {
                // alert('Поля "Контрагент" и "Договора" обязательны при сценарии "Выполнение"')
                throw new Error('Поля "Контрагент" и "Договора" обязательны при сценарии "Выполнение"')
            }
            await $authHost.patch(userIsAdmin ? 'form-budget-submission/admin/row-patch' : 'form-budget-submission/row-patch', newData)
                .then(response => {
                    if (response.status === 200) {
                        const newTableData = [...myDataSource];
                        const RowIndex = myDataSource.findIndex(el => el.id === newData['id']);
                        // if (columnId === 'amount_proposed') {
                        //     newTableData[RowIndex] = { ...newTableData[RowIndex], [columnId]: value, amount_accepted: value, status: response.data };
                        // } else {
                        //     newTableData[RowIndex] = { ...newTableData[RowIndex], [columnId]: value, status: response.data };
                        // }
                        newTableData[RowIndex] = {
                            ...newTableData[RowIndex],
                            [columnId]: value,
                            ...(columnId === 'amount_proposed' ? { amount_accepted: value } : {}),
                            status: response.data
                        };
                        setMyDataSource(newTableData)
                        setDefaultDataSource(newTableData)
                    }
                })
        } catch (e) {
            if(e.message === 'Request failed with status code 422')
                {
                    alert('Поле должно быть заполнено');
                }
                else{
                    alert(e.message)
                }
        } finally {
            setLoading(false);
        }
    }

    // const AdminrowPatch = async (data, columnId, value) => {
    //     setLoading(true);
    //     const newData = { ...data };
    //     newData[columnId] = value;
    //     delete newData.status;
    //     delete newData.date;
    //     newData["user_id"] = Number(user.user_ID);
    //     try {
    //         await $authHost.patch('/form-budget-submission/admin/row-patch', newData)
    //             .then(response => {
    //                 if (response.status === 200) {
    //                     const newTableData = [...myDataSource];
    //                     const RowIndex = newTableData.findIndex(el => el.id === newData['id']);
    //                     newTableData[RowIndex] = { ...newTableData[RowIndex], [columnId]: value, };
    //                     setMyDataSource(newTableData)
    //                 }
    //             })
    //     } catch (e) {
    //     } finally {
    //         setLoading(false);
    //     }
    // }

    function validateDataByCase(data){
        switch (data['case']) {
            case 'Выполнение':
                return data.organization !== null && data.location !== null && data.object_code !== null && data.reporting_period !== null
                    && data.subcontractor !== null && data.contract_number !== null && data.contract_number_gp !== null
                    && data.dc_number_contract_gp !== null && data.work !== null && data.amount_proposed !== undefined && data.amount_accepted !== undefined;
            case 'Аванс':
                return data.organization !== null && data.location !== null && data.object_code !== null && data.reporting_period !== null
                    && data.work !== null && data.amount_proposed !== undefined && data.amount_accepted !== undefined;
            default:
                return false;
        }
    }

    const checkFullData = (data, columnId, value, rowIndex, cb) => {
        if(!someEditingNow || someEditingNow === data['id']) {
            data[columnId] = value;
            if (columnId === 'location') {data.object_code = null}
            //если все хорошо
            if(validateDataByCase(data)) {
                cb();
                setSomeEditingNow(null);
            }
            else { //если что то не заполнено
                setSomeEditingNow(data['id']);
                const newTableData = [...myDataSource];
                const RowIndex = myDataSource.findIndex(el => el.id === data['id']);
                newTableData[RowIndex] = {
                    ...newTableData[RowIndex],
                    [columnId]: value,
                };
                setMyDataSource(newTableData)
                setDefaultDataSource(newTableData)
            }
        } else {
            gridDataRef.current.cancelEdit(columnId, rowIndex);
        }


    };

    const onEditComplete = ({ data, columnId, value, rowIndex }) => {
        checkFullData(data, columnId, value, rowIndex, () => {rowPatch(data, columnId, value)});
    };

    const onFilterValueChange = useCallback(
        value => {
            setFilterValue(value);
            dispatch(setActualPage(1));
        },
        [setFilterValue]
    );

    const rowCount = React.useMemo(() => {
        const filteredData = myDataSource.filter((item) => {
            for (let filter of filterValue) {
                const { name, operator, value } = filter;
                // if(value && (name === 'subcontractor' || name === 'contract_number' || name === 'contract_number_gp' || name === 'dc_number_contract_gp') && !item[name]){
                //     return false;
                if(value && !item[name]){
                    return false;
                }else if (value && operator === 'contains' && item[name].toLowerCase().indexOf(value.toLowerCase()) === -1) {
                    return false;
                } else if (value && name === 'date' && operator === 'eq' && item[name] !== moment(value, 'DD.MM.YYYY').format('YYYY-MM-DDTHH:mm:ss')) {
                    return false;
                } else if (value && name !== 'date' && operator === 'eq' && item[name] !== value) {
                    return false;
                }
            }
            return true;
        });
        dispatch(setRowsPages(filteredData.length));
        return filteredData.length;
    }, [filterValue, myDataSource]);

    const selectScenarioFilter = [
        { id: 0, label: 'Аванс' },
        { id: 1, label: 'Выполнение' },
    ]
    const selectStatusFilter = [
        { id: 0, label: 'Ожидание' },
        { id: 1, label: 'На согласовании' },
        { id: 2, label: 'Согласовано' },
        { id: 3, label: 'Не согласовано' },
        ...(userIsAdmin ? [{ id: 4, label: 'Удалено' }] : []),
        { id: 5, label: 'Аннулировано' },
    ]

    // const render = ({value}) => {
    //     const lowerSearchText = searchTextRef.current.toLowerCase();
    //     if (!lowerSearchText) {
    //         return value;
    //     }
    //     const str = value + ''; // get string value
    //     const v = str.toLowerCase(); // our search is case insensitive
    //     const index = v.indexOf(lowerSearchText);
    //
    //     if (index === -1) {
    //         return value;
    //     }
    //
    //     return [
    //         <span key="before">{str.slice(0, index)}</span>,
    //         <span key="match" style={{ background: 'yellow', fontWeight: 'bold' }}>
    //             {str.slice(index, index + lowerSearchText.length)}
    //         </span>,
    //         <span key="after">{str.slice(index + lowerSearchText.length)}</span>,
    //     ];
    // }

    function render (value) {
        const lowerSearchText = searchTextRef.current.toLowerCase();
        if (!lowerSearchText) {
            return value;
        }
        const str = value + ''; // get string value
        const v = str.toLowerCase(); // our search is case insensitive
        const index = v.indexOf(lowerSearchText);

        if (index === -1) {
            return value;
        }

        return [
            <span key="before">{str.slice(0, index)}</span>,
            <span key="match" style={{ background: 'yellow', fontWeight: 'bold' }}>
                {str.slice(index, index + lowerSearchText.length)}
            </span>,
            <span key="after">{str.slice(index + lowerSearchText.length)}</span>,
        ];
    }

    const checkboxColumn = {
        renderCheckbox: (checkboxProps, cellProps) => {
            const { onChange, checked } = checkboxProps
            const stat = cellProps.data['status'];
            switch (userIsAdmin) {
                case true:
                    return (
                        (stat === 'На согласовании' && someEditingNow === null) &&
                        <div
                            onClick={e => {
                                e.stopPropagation();
                                onChange(!checked);
                            }}
                        >
                            <Checkbox checked={
                                checked === true
                            } size="small" sx={{ p: 0 }} />
                        </div>

                    );
                case false:
                    return (
                        (stat === 'Ожидание' && someEditingNow === null) &&
                        <div
                            onClick={e => {
                                e.stopPropagation();
                                onChange(!checked);
                            }}
                        >
                            <Checkbox checked={
                                checked === true
                            } size="small" sx={{ p: 0 }} />
                        </div>
                    );
                default: break;
            }

        }
    }

    const columns = [
        { id: 'id', name: 'id', header: 'ID', maxWidth: 50, defaultFlex: 1, visible: true, editable: false, type: 'number'},
        {
            id: 'date', name: 'date', header: 'Дата', defaultFlex: 1, visible: true, type: 'date',
            editable: false,
            dateFormat: 'DD.MM.YYYY',
            filterEditor: DateFilter,
            filterEditorProps: (props, { index }) => {
                return {
                    dateFormat: 'DD.MM.YYYY',
                }
            },
            render: ({ value, cellProps: { dateFormat } }) =>
                render(moment(value).format(dateFormat))
        },
        {
            id: 'executor',
            name: 'executor',
            header: 'Исполнитель',
            defaultFlex: 1,
            visible: true,
            editable: false,
            // render
            render: ({ value, data }) => {
                return render(value);
            },
        },
        {
            id: 'case',
            name: 'case',
            header: 'Сценарий',
            defaultWidth: 100,
            defaultFlex: 1,
            visible: true,
            editable: false,
            filterEditor: SelectFilter,
            filterEditorProps: {
                idProperty: 'label',
                placeholder: 'Все',
                dataSource: selectScenarioFilter
            },

            // filterEditor: (editProps) => {
            //     const handleFilterChange = (value) => {
            //         const filter = {
            //             name: 'case',
            //             operator: 'eq',
            //             type: 'string',
            //             value: value.label
            //         };
            //         editProps.onChange(filter);
            //         setCaseValue(value.label);
            //     }
            //     return (
            //         <div style={{border: 0, backgroundColor: '#ffffff',minHeight: '41px', borderTop: '1px solid #e4e3e2', padding: '4px'}}>
            //             <AutocompleteFilterDefiniteOptions
            //                 options={selectScenarioFilter}
            //                 value={caseValue}
            //                 onBlur={editProps.onComplete}
            //                 onChange={handleFilterChange}
            //             />
            //         </div>
            //     )
            // },
            // render
            render: ({ value, data }) => {
                return render(value);
            },
        },
        {
            id: 'organization',
            name: 'organization',
            header: 'Организация',
            defaultFlex: 1,
            visible: true,
            editable: (editValue, cellProps) => {
                const stat = cellProps.data['status'];
                switch (userIsAdmin) {
                    case false:
                        return (stat === 'Ожидание') || (stat === 'Не согласовано');
                    case true:
                        return cellProps.data.executor === user.full_name ? // проверяем совпадает ли имя администратора с исполнителем
                            (stat === 'На согласовании') || (stat === 'Ожидание') || (stat === 'Не согласовано') :
                            (stat === 'На согласовании')
                    default:
                        break;
                }
            },
            filterEditor: (editProps) => {
                const handleFilterChange = (value) => {
                    const filter = {
                        name: 'organization',
                        operator: 'eq',
                        type: 'string',
                        value: value
                    };
                    editProps.onChange(filter);
                    setOrganizationValue(value)
                }
                return (
                    <div style={{border: 0, backgroundColor: '#ffffff',minHeight: '41px', borderTop: '1px solid #e4e3e2', padding: '4px'}}>
                        <AutocompleteComponent
                            placeholder="Все"
                            field="org"
                            value={organizationValue}
                            onBlur={editProps.onComplete}
                            onChange={handleFilterChange}
                        />
                    </div>
                )
            },
            renderEditor: (editProps) => {
                document.onkeydown = function(e) {
                    if (e.key == "Escape" || e.key == "Esc") {
                        editProps.onComplete()
                    }
                }
                return (
                    <AutocompleteComponent
                        field="org"
                        value={editProps.value}
                        onBlur={editProps.onComplete}
                        onChange={editProps.onChange}
                    />
                )

            },
            // render
            render: ({ value, data }) => {
                return render(value);
            },
            onRender: (cellProps, {data}) => {
                cellProps.style.background = data.organization === null && '#ffe8e8';
            }
        },
        {
            id: 'location',
            name: 'location',
            header: 'Город',
            group: 'object',
            defaultFlex: 1,
            visible: true,
            editable: (editValue, cellProps) => {
                const stat = cellProps.data['status'];
                switch (userIsAdmin) {
                    case false:
                        return (stat === 'Ожидание') || (stat === 'Не согласовано');
                    case true:
                        return cellProps.data.executor === user.full_name ? // проверяем совпадает ли имя администратора с исполнителем
                            (stat === 'На согласовании') || (stat === 'Ожидание') || (stat === 'Не согласовано') :
                            (stat === 'На согласовании')
                    default:
                        break;
                }
            },
            filterEditor: (editProps) => {
                const handleFilterChange = (value) => {
                    const filter = {
                        name: 'location',
                        operator: 'eq',
                        type: 'string',
                        value: value
                    };
                    editProps.onChange(filter);
                    setLocationValue(value)
                }
                return (
                    <div style={{border: 0, backgroundColor: '#ffffff',minHeight: '41px', borderTop: '1px solid #e4e3e2', padding: '4px'}}>
                        <AutocompleteComponent
                            placeholder="Все"
                            field="loc"
                            value={locationValue}
                            onBlur={editProps.onComplete}
                            onChange={handleFilterChange}
                        />
                    </div>
                )
            },
            renderEditor: (editProps, cellProps) => {
                document.onkeydown = function(e) {
                    if (e.key == "Escape" || e.key == "Esc") {
                        editProps.onComplete()
                    }
                }
                return (
                    <AutocompleteComponent
                        pair_value={cellProps.data.object_code}
                        field="loc"
                        value={editProps.value}
                        onBlur={editProps.onComplete}
                        onChange={editProps.onChange}
                    />
                )
            },
            render: ({ value, data }) => {
                return render(value);
            },
            onRender: (cellProps, {data}) => {
                cellProps.style.background = data.location === null && '#ffe8e8';
            }
        },
        {
            id: 'object_code',
            name: 'object_code',
            header: 'ID объекта',
            group: 'object',
            defaultFlex: 1,
            visible: true,
            editable: (editValue, cellProps) => {
                const stat = cellProps.data['status'];
                switch (userIsAdmin) {
                    case false:
                        return (stat === 'Ожидание') || (stat === 'Не согласовано');
                    case true:
                        return cellProps.data.executor === user.full_name ? // проверяем совпадает ли имя администратора с исполнителем
                            (stat === 'На согласовании') || (stat === 'Ожидание') || (stat === 'Не согласовано') :
                            (stat === 'На согласовании')
                    default:
                        break;
                }
            },
            renderEditor: (editProps, cellProps) => {
                document.onkeydown = function(e) {
                    if (e.key == "Escape" || e.key == "Esc") {
                        editProps.onComplete()
                    }
                }
                return (
                    <AutocompleteObjectCode
                        pair_value={cellProps.data.location}
                        value={editProps.value}
                        onBlur={editProps.onComplete}
                        onChange={editProps.onChange}
                    />
                )
            },
            // render
            render: ({ value, data }) => {
                return render(value);
            },
            onRender: (cellProps, {data}) => {
                cellProps.style.background = data.object_code === null && '#ffe8e8';
            }
            // render: ({value}) => value.toString(),
            // filterEditor: TextFilter,
            // filterEditorProps: {
            //      filterValueParser: (value) => value.toString(),
            // }
        },
        {
            id: 'subcontractor',
            name: 'subcontractor',
            header: 'Контрагент/Субподрядчик',
            // minWidth: 150,
            defaultFlex: 1,
            visible: true,
            editable: (editValue, cellProps) => {
                const stat = cellProps.data['status'];
                switch (userIsAdmin) {
                    case false:
                        return (stat === 'Ожидание') || (stat === 'Не согласовано');
                    case true:
                        return cellProps.data.executor === user.full_name ? // проверяем совпадает ли имя администратора с исполнителем
                            (stat === 'На согласовании') || (stat === 'Ожидание') || (stat === 'Не согласовано') :
                            (stat === 'На согласовании')
                    default:
                        break;
                }
            },
            renderEditor: (editProps) => {
                document.onkeydown = function(e) {
                    if (e.key == "Escape" || e.key == "Esc") {
                        editProps.onComplete()
                    }
                }
                return (
                    <AutocompleteComponent
                        field="sub"
                        value={editProps.value}
                        onBlur={editProps.onComplete}
                        onChange={editProps.onChange}
                    />
                )
            },
            // render
            render: ({ value, data }) => {
                return render(value);
            },
            onRender: (cellProps, {data}) => {
                if(data.case === 'Выполнение'){
                    cellProps.style.background = data.subcontractor === null && '#ffe8e8';
                }
            }
        },
        {
            id: 'contract_number',
            name: 'contract_number',
            header: '№ Договора',
            group: 'contracts',
            defaultFlex: 1,
            visible: true,
            editable: (editValue, cellProps) => {
                const stat = cellProps.data['status'];
                switch (userIsAdmin) {
                    case false:
                        return (stat === 'Ожидание') || (stat === 'Не согласовано');
                    case true:
                        return cellProps.data.executor === user.full_name ? // проверяем совпадает ли имя администратора с исполнителем
                            (stat === 'На согласовании') || (stat === 'Ожидание') || (stat === 'Не согласовано') :
                            (stat === 'На согласовании')
                    default:
                        break;
                }
            },
            renderEditor: (editProps) => {
                document.onkeydown = function(e) {
                    if (e.key == "Escape" || e.key == "Esc") {
                        editProps.onComplete()
                    }
                }
                return (
                    <AutocompleteComponent
                        field="contract"
                        value={editProps.value}
                        onBlur={editProps.onComplete}
                        onChange={editProps.onChange}
                    />
                )
            },
            // render
            render: ({ value, data }) => {
                return value && render(value).toString();
            },
            onRender: (cellProps, {data}) => {
                if(data.case === 'Выполнение'){
                    cellProps.style.background = data.contract_number === null && '#ffe8e8';
                }
            }
        },
        {
            id: 'contract_number_gp',
            name: 'contract_number_gp',
            header: '№ Договора ГП',
            group: 'contracts',
            defaultFlex: 1,
            visible: true,
            editable: (editValue, cellProps) => {
                const stat = cellProps.data['status'];
                switch (userIsAdmin) {
                    case false:
                        return (stat === 'Ожидание') || (stat === 'Не согласовано');
                    case true:
                        return cellProps.data.executor === user.full_name ? // проверяем совпадает ли имя администратора с исполнителем
                            (stat === 'На согласовании') || (stat === 'Ожидание') || (stat === 'Не согласовано') :
                            (stat === 'На согласовании')
                    default:
                        break;
                }
            },
            renderEditor: (editProps) => {
                document.onkeydown = function(e) {
                    if (e.key == "Escape" || e.key == "Esc") {
                        editProps.onComplete()
                    }
                }
                return (
                    <AutocompleteComponent
                        field="gp"
                        value={editProps.value}
                        onBlur={editProps.onComplete}
                        onChange={editProps.onChange}
                    />
                )
            },
            // render
            render: ({ value, data }) => {
                return render(value);
            },
            onRender: (cellProps, {data}) => {
                if(data.case === 'Выполнение'){
                    cellProps.style.background = data.contract_number_gp === null && '#ffe8e8';
                }
            }
        },
        {
            id: 'dc_number_contract_gp',
            name: 'dc_number_contract_gp',
            header: 'ДС к Договору ГП',
            group: 'contracts',
            defaultFlex: 1,
            visible: true,
            editable: (editValue, cellProps) => {
                const stat = cellProps.data['status'];
                switch (userIsAdmin) {
                    case false:
                        return (stat === 'Ожидание') || (stat === 'Не согласовано');
                    case true:
                        return cellProps.data.executor === user.full_name ? // проверяем совпадает ли имя администратора с исполнителем
                            (stat === 'На согласовании') || (stat === 'Ожидание') || (stat === 'Не согласовано') :
                            (stat === 'На согласовании')
                    default:
                        break;
                }
            },
            renderEditor: (editProps) => {
                document.onkeydown = function(e) {
                    if (e.key == "Escape" || e.key == "Esc") {
                        editProps.onComplete()
                    }
                }
                return (
                    <AutocompleteComponent
                        field="dc"
                        value={editProps.value}
                        onBlur={editProps.onComplete}
                        onChange={editProps.onChange}
                    />
                )
            },
            // render
            render: ({ value, data }) => {
                return render(value);
            },
            onRender: (cellProps, {data}) => {
                if(data.case === 'Выполнение'){
                    cellProps.style.background = data.dc_number_contract_gp === null && '#ffe8e8';
                }
            }
        },
        {
            id: 'work', name: 'work', header: 'Наименование работ', defaultFlex: 1, visible: true,
            editable: (editValue, cellProps) => {
                const stat = cellProps.data['status'];
                switch (userIsAdmin) {
                    case false:
                        return (stat === 'Ожидание') || (stat === 'Не согласовано');
                    case true:
                        return cellProps.data.executor === user.full_name ? // проверяем совпадает ли имя администратора с исполнителем
                            (stat === 'На согласовании') || (stat === 'Ожидание') || (stat === 'Не согласовано') :
                            (stat === 'На согласовании')
                    default:
                        break;
                }
            },
            renderEditor: (editProps) => {
                document.onkeydown = function(e) {
                    if (e.key == "Escape" || e.key == "Esc") {
                        editProps.onComplete()
                    }
                }
                return (
                    <AutocompleteComponent
                        field="work"
                        value={editProps.value}
                        onBlur={editProps.onComplete}
                        onChange={editProps.onChange}
                    />
                )
            },
            // render
            render: ({ value, data }) => {
                return render(value);
            },
            onRender: (cellProps, {data}) => {
                cellProps.style.background = data.work === null && '#ffe8e8';
            }
        },
        {
            id: 'amount_proposed',
            name: 'amount_proposed',
            header: 'Предложенная сумма',
            type: 'string',
            defaultFlex: 1,
            visible: true,
            editable: (editValue, cellProps) => {
                const stat = cellProps.data['status'];
                switch (userIsAdmin) {
                    case false:
                        return (stat === 'Ожидание') || (stat === 'Не согласовано');//странная хрень
                    case true:
                        return cellProps.data.executor === user.full_name ? // проверяем совпадает ли имя администратора с исполнителем
                            (stat === 'На согласовании') || (stat === 'Ожидание') || (stat === 'Не согласовано') :
                            false
                    default:
                        break;
                }
            },
            renderEditor: (editProps) => {
                document.onkeydown = function(e) {
                    if (e.key == "Escape" || e.key == "Esc") {
                        editProps.onComplete()
                    }
                }
                return (
                    <div style={{width: '100%'}}>
                        <NumberEditorComponent
                            negative={false}
                            value={editProps.value}
                            onBlur={editProps.onComplete}
                            onChange={editProps.onChange}
                        />
                    </div>
                )
            },
            render: ({ value, data }) => {
                if (value !== data.amount_accepted) {
                    return <del>{render(value)}</del>;
                }
                return render(value);
            },
            onRender: (cellProps, {data}) => {
                cellProps.style.background = data.amount_proposed === null && '#ffe8e8';
            }
        },
        {
            id: "amount_accepted",
            name: "amount_accepted",
            header: "Согласованная сумма",
            defaultFlex: 1,
            visible: true,
            editable: (editValue, cellProps) => {
                const stat = cellProps.data["status"];
                switch (userIsAdmin) {
                    case false:
                        return false;
                    case true:
                        return stat === "На согласовании";
                    default:
                        break;
                }
            },
            renderEditor: (editProps) => {
                document.onkeydown = function(e) {
                    if (e.key == "Escape" || e.key == "Esc") {
                        editProps.onComplete()
                    }
                }
                return (
                    <div style={{width: '100%'}}>
                        <NumberEditorComponent
                            negative={true}
                            value={editProps.value}
                            onBlur={editProps.onComplete}
                            onChange={editProps.onChange}
                        />
                    </div>

                )
            },
            // render
            render: ({ value, data }) => {
                return render(value);
            },
            onRender: (cellProps, {data}) => {
                cellProps.style.background = data.amount_accepted === null && '#ffe8e8';
            }
            // editorValidator: (value) => {
            //     const floatValue = parseFloat(value);
            //     return !isNaN(floatValue) && floatValue.toFixed(2) === value;
            // },
        },
        {
            id: 'reporting_period',
            name: 'reporting_period',
            header: 'дата отчетного периода',
            group: 'information',
            defaultFlex: 1,
            visible: true,
            editable: (editValue, cellProps) => {
                const stat = cellProps.data['status'];
                switch (userIsAdmin) {
                    case false:
                        return (stat === 'Ожидание') || (stat === 'Не согласовано');
                    case true:
                        return cellProps.data.executor === user.full_name ? // проверяем совпадает ли имя администратора с исполнителем
                            (stat === 'На согласовании') || (stat === 'Ожидание') || (stat === 'Не согласовано') :
                            (stat === 'На согласовании')
                    default:
                        break;
                }
            },
            renderEditor: (editProps) => {
                document.onkeydown = function(e) {
                    if (e.key == "Escape" || e.key == "Esc") {
                        editProps.onComplete()
                    }
                }
                return (
                    <AutocompletePeriod
                        value={editProps.value}
                        onBlur={editProps.onComplete}
                        onChange={editProps.onChange}
                    />
                )
            },
            // render
            render: ({ value, data }) => {
                return render(value);
            },
            onRender: (cellProps, {data}) => {
                cellProps.style.background = data.reporting_period === null && '#ffe8e8';
            }
        },
        {
            id: 'note',
            name: 'note',
            header: 'Примечание',
            group: 'information',
            defaultFlex: 1,
            visible: true,
            editable: (editValue, cellProps) => {
                const stat = cellProps.data['status'];
                switch (userIsAdmin) {
                    case false:
                        return (stat === 'Ожидание') || (stat === 'Не согласовано');
                    case true:
                        return cellProps.data.executor === user.full_name ? // проверяем совпадает ли имя администратора с исполнителем
                            (stat === 'На согласовании') || (stat === 'Ожидание') || (stat === 'Не согласовано') :
                            (stat === 'На согласовании')
                    default:
                        break;
                }
            },
            // render
            render: ({ value, data }) => {
                return render(value);
            },
        },
        {
            id: 'status', name: 'status', header: 'Статус', group: 'information', defaultFlex: 1, visible: true,
            editable: false,
            minWidth: 100,
            editor: SelectEditor,
            editorProps: {
                idProperty: 'label',
                // filter: true, // добавляем фильтр для поиска вариантов
                // editable: false, // делаем редактор активным для выбора варианта
                dataSource: selectStatusFilter,
                renderOption: (item) => item.label,
            },
            filterEditor: SelectFilter,
            filterEditorProps: {
                idProperty: 'label',
                placeholder: 'Все',
                dataSource: selectStatusFilter
            },
            render: ({ value }) => {
                switch (value) {
                    case 'Ожидание':
                        return (
                            <Chip label="ожидание" size="small" />
                        );
                    case 'На согласовании':
                        return (
                            <Chip label="на согласовании" color="primary" size="small" />
                        );
                    case 'Согласовано':
                        return (
                            <Chip label="согласовано" color="success" size="small" />
                        );
                    case 'Не согласовано':
                        return (
                            <Chip label="не согласовано" color="error" size="small" />
                        );
                    case 'Удалено':
                        return (
                            <Chip label="удалено" color="warning" size="small" />
                        );
                    case 'Аннулировано':
                        return (
                            <Chip label="аннулировано" color="secondary" size="small" />
                        );
                    default:
                        return value;
                }
            }
        },
        {
            id: 'options', name: 'options', header: '', defaultFlex: 1, visible: true, editable: false,
            render: ({ data }) => {
                const RowIndex = myDataSource.findIndex(el => el.id === data.id);
                // const handleDelete = async () => {
                //     try {
                //         setLoading(true);
                //         await $authHost.delete(`/form-budget-submission/row-delete?id=${data.id}`)
                //             .then(response => {
                //                 if (response.status === 200) {
                //                     if (userIsAdmin) {
                //                         const newRow = { ...data, status: 'Удалено' }
                //                         const newDataSource = myDataSource.map((row) => {
                //                             if (row.id === newRow.id) {
                //                                 return newRow;
                //                             }
                //                             return row;
                //                         })
                //                         setMyDataSource(newDataSource)
                //                         setDefaultDataSource(newDataSource)
                //                     } else {
                //                         const newDataSource = [...myDataSource.filter(el => el.id !== data.id)]
                //                         setMyDataSource(newDataSource)
                //                         setDefaultDataSource(newDataSource)
                //                         dispatch(selectRows({}))
                //                     }
                //                 }
                //             })
                //     } catch (e) {
                //     } finally {
                //         setLoading(false);
                //     }
                // }
                // const handleAnnul = async () => {
                //     try {
                //         setLoading(true);
                //         await $authHost.patch(`/form-budget-submission/row-annul?id=${data.id}`)
                //             .then(response => {
                //                 if (response.status === 200) {
                //                     const newTableData = [...myDataSource];
                //                     newTableData[rowIndex] = { ...newTableData[rowIndex], 'status': 'Аннулировано', };
                //                     setMyDataSource(newTableData)
                //                     setDefaultDataSource(newTableData)
                //                 }
                //             })
                //     } catch (e) {
                //     } finally {
                //         setLoading(false);
                //     }
                // }
                const handleRecovery = async () => {
                    try {
                        setLoading(true);
                        await $authHost.patch(`/form-budget-submission/row-recovery?id=${data.id}&reporting_period=${data.reporting_period}`)
                            .then(response => {
                                if (response.status === 200) {
                                    const newRow = { ...data, status: response.data }
                                    const newDataSource = myDataSource.map((row) => {
                                        if (row.id === newRow.id) {
                                            return newRow;
                                        }
                                        return row;
                                    })
                                    setMyDataSource(newDataSource)
                                    setDefaultDataSource(newDataSource)
                                }
                            })
                    } catch (e) {
                    } finally {
                        setLoading(false);
                    }
                }
                // const handleCheck = (status) => async () => {
                //     try {
                //         setLoading(true);
                //         await $authHost.patch(`/form-budget-submission/row-approval?approval=${status}&id=${data.id}`)
                //             .then(response => {
                //                 if (response.status === 200) {
                //                     const newTableData = [...myDataSource];
                //                     newTableData[rowIndex] = { ...newTableData[rowIndex], 'status': status, };
                //                     setMyDataSource(newTableData)
                //                     setDefaultDataSource(newTableData)
                //                 }
                //             })
                //     } catch (e) {
                //     } finally {
                //         setLoading(false);
                //     }
                // }
                const handleConfirmation = (status) => {
                    setConfirmation({
                        id: data.id,
                        status
                    })
                }
                const handleButtonGroupAction = async () => {
                    try {
                        setLoading(true);
                        switch (confirmation.status) {
                            case 'Аннулировано':
                                await $authHost.patch(`/form-budget-submission/row-annul?id=${data.id}`)
                                    .then(response => {
                                        if (response.status === 200) {
                                            const newTableData = [...myDataSource];
                                            newTableData[RowIndex] = { ...newTableData[RowIndex], 'status': 'Аннулировано', };
                                            setMyDataSource(newTableData)
                                            setDefaultDataSource(newTableData)
                                        }
                                    })
                                break;
                            case 'Согласовано':
                            case 'Не согласовано':
                                await $authHost.patch(`/form-budget-submission/row-approval?approval=${confirmation.status}&id=${data.id}`)
                                    .then(response => {
                                        if (response.status === 200) {
                                            const newTableData = [...myDataSource];
                                            newTableData[RowIndex] = { ...newTableData[RowIndex], 'status': confirmation.status, };
                                            setMyDataSource(newTableData)
                                            setDefaultDataSource(newTableData)
                                        }
                                    })
                                break;
                            case 'Удалено':
                                await $authHost.delete(`/form-budget-submission/row-delete?id=${data.id}`)
                                    .then(response => {
                                        if (response.status === 200) {
                                            if (userIsAdmin) {
                                                const newRow = { ...data, status: 'Удалено' }
                                                const newDataSource = myDataSource.map((row) => {
                                                    if (row.id === newRow.id) {
                                                        return newRow;
                                                    }
                                                    return row;
                                                })
                                                setMyDataSource(newDataSource)
                                                setDefaultDataSource(newDataSource)
                                            } else {
                                                const newDataSource = [...myDataSource.filter(el => el.id !== data.id)]
                                                setMyDataSource(newDataSource)
                                                setDefaultDataSource(newDataSource)
                                                dispatch(selectRows({}))
                                            }
                                        }
                                    })
                                break;
                            case 'Восстановлено':
                                break;

                            default:
                                break;
                        }
                    } catch (e) {
                    } finally {
                        setLoading(false)
                        setConfirmation({})
                    }
                }
                if (confirmation.id == data.id) {
                    return (
                        // <Box
                        //     sx={{
                        //         display: 'inline-block',
                        //         padding: 0,
                        //         border: 'solid 1px green',
                        //         borderRadius: '30px',
                        //         backgroundColor: 'green'
                        //     }}
                        // >
                        <>
                            <IconButton size="small" onClick={() => setConfirmation({})}>
                                <ClearIcon fontSize="inherit" />
                            </IconButton>
                            <IconButton size="small" onClick={handleButtonGroupAction}>
                                <CheckIcon fontSize="inherit" />
                            </IconButton>
                        </>
                        // </Box>
                    )
                } else {
                    switch (data.status) {
                        case 'На согласовании':
                            return (
                                <div style={{ display: 'inline-block' }}>
                                    {userIsAdmin &&
                                        <>
                                            <IconButton size="small" sx={{ pl: 0 }} disabled={someEditingNow !== null} onClick={() => handleConfirmation('Согласовано')}
                                                color='success'>
                                                <CheckCircleOutlineIcon fontSize="inherit" />
                                            </IconButton>
                                            <IconButton size="small" sx={{ pl: 0 }} disabled={someEditingNow !== null} onClick={() => handleConfirmation('Не согласовано')}
                                                color='error'>
                                                <BlockIcon fontSize="inherit" />
                                            </IconButton>
                                            <IconButton size="small" sx={{ pl: 0 }} disabled={someEditingNow !== null} onClick={() => handleConfirmation('Удалено')}>
                                                <DeleteIcon fontSize="inherit" />
                                            </IconButton>
                                        </>
                                    }
                                </div>
                            )
                        case 'Удалено':
                            return (
                                <div style={{ display: 'inline-block', padding: 0 }}>
                                    {userIsAdmin &&
                                        <IconButton size="small" onClick={handleRecovery}>
                                            <ReplayIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                </div>
                            )
                        case 'Согласовано':
                            return (
                                <div style={{ display: 'inline-block', padding: 0 }}>
                                    {userIsAdmin &&
                                        <IconButton size="small" disabled={someEditingNow !== null} onClick={() => handleConfirmation('Аннулировано')}>
                                            <DeleteForeverIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                </div>
                            )
                        case 'Не согласовано':
                            return (
                                <div style={{ display: 'inline-block', padding: 0 }}>
                                    {userIsAdmin &&
                                        <IconButton size="small" disabled={someEditingNow !== null} sx={{ pl: 0 }} onClick={() => handleConfirmation('Согласовано')}
                                            color='success'>
                                            <CheckCircleOutlineIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                </div>
                            )
                        case 'Ожидание':
                            return (
                                <div style={{ display: 'inline-block', padding: 0 }}>
                                    {(!userIsAdmin || data.executor === user.full_name) &&  // дополнительно проверяем совпадает ли имя администратора с исполнителем
                                        <IconButton size="small" disabled={someEditingNow !== null} onClick={() => handleConfirmation('Удалено')}>
                                            <DeleteIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                </div>
                            )
                        default:
                            break;
                    }
                }

                // switch (userIsAdmin) {
                //     case true:
                //         return (
                //             <div style={{ display: 'inline-block', padding: 0 }}>
                //                 {stat === 'На согласовании' &&
                //                     <>
                //                         <IconButton size="small" sx={{pl:0}} onClick={handleCheck('Согласовано')}
                //                             color='success'>
                //                             <CheckCircleOutlineIcon fontSize="inherit" />
                //                         </IconButton>
                //                         <IconButton size="small" sx={{pl:0}} onClick={handleCheck('Не согласовано')}
                //                             color='error'>
                //                             <BlockIcon fontSize="inherit" />
                //                         </IconButton>
                //                         <IconButton size="small" sx={{pl:0}} onClick={handleDelete}>
                //                             <DeleteIcon fontSize="inherit" />
                //                         </IconButton>
                //                     </>
                //                 }
                //                 {stat === 'Удалено' &&
                //                     <IconButton size="small" onClick={handleRecovery}>
                //                         <ReplayIcon fontSize="inherit" />
                //                     </IconButton>
                //                 }
                //             </div>
                //         );
                //     case false:
                //         return (
                //             <div style={{ display: 'inline-block' }}>
                //                 {stat === 'Ожидание' ?
                //                     <>
                //                         <IconButton size="small" onClick={handleDelete}>
                //                             <DeleteIcon fontSize="inherit" />
                //                         </IconButton>
                //                     </>
                //                     :
                //                     <></>
                //                 }
                //             </div>
                //         );
                //     default:
                //         break;

                // }

            }
        },
    ];
    const adminColumnId = ['executor']
    const menuColumns = userIsAdmin ?  //список колонок для меню выбора колонок
        columns.filter((column) => column.name !== 'status' && column.name !== 'options' && column.name !== 'id' && column.name !== 'date') :
        columns.filter((column, index) => column.name !== 'status' && column.name !== 'options' && column.name !== 'date' && column.name !== 'id' && !adminColumnId.includes(column.name));

    const visibleColumns = userIsAdmin ?  //список колонок для отрисовки в таблице, с учетом отображаемых(showColumns)
        columns.filter((column) => showColumns.includes(column.name)) :
        columns.filter((column, index) => !adminColumnId.includes(column.name) && showColumns.includes(column.name));


    const handleColumnToggle = (e, column) => {
        const isVisible = e.target.checked;
        // const index = visibleColumns.findIndex(c => c.name === column.name);
        // dispatch(updateVisibleColumns({
        //     index: index,
        //     isVisible: isVisible,
        // }))
        {
            isVisible ?
                dispatch(showColumn(column.name))
                :
                dispatch(hideColumn(column.name))
        }
    };
    const groups = [
        { name: 'object', header: 'Объект' },
        { name: 'contracts', header: 'Договора' },
        { name: 'work_amount', header: 'Работы и суммы' },
        { name: 'information', header: 'Общая информация' },
    ]


    const defaultFilterValue = [
        { name: 'date', operator: 'eq', type: 'date', value: null },
        { name: 'executor', operator: 'contains', type: 'string', value: null },
        { name: 'case', operator: 'eq', type: 'select', value: null },
        { name: 'organization', operator: 'contains', type: 'string', value: null },
        { name: 'location', operator: 'contains', type: 'string', value: null },
        { name: 'object_code', operator: 'contains', type: 'string', value: null },
        { name: 'subcontractor', operator: 'contains', type: 'string', value: null },
        { name: 'contract_number', operator: 'contains', type: 'string', value: null },
        { name: 'contract_number_gp', operator: 'contains', type: 'string', value: null },
        { name: 'dc_number_contract_gp', operator: 'contains', type: 'string', value: null },
        { name: 'work', operator: 'contains', type: 'string', value: null },
        // {name: 'amount', operator: 'contains', type: 'string', value: null},
        // {name: 'amount_adjustment', operator: 'contains', type: 'string', value: null},
        { name: 'reporting_period', operator: 'contains', type: 'string', value: null },
        { name: 'note', operator: 'contains', type: 'string', value: null },
        { name: 'status', operator: 'eq', type: 'select', value: null },
    ];


    const gridStyle = { height: '63vh', columnMinWidth: 100, margin: '0 16px 0 16px' }

    const handleChangeCheck = (event) => {
        dispatch(updateCheckbox({
            name: event.target.name,
            // checked: event.target.checked,
        }))
    };


    const onSelectionChange = useCallback(({ selected }) => {
        dispatch(selectRows(selected));
    }, []);

    // const paginationProps = {
    //     pageSize: 3,
    //     showPageSizeSelector: true,
    //     showRefreshButton: true,
    //     showPageInfo: true,
    //     pageSizeOptions: [3, 5, 10],
    // };
    // const handleMenuReset = (event) => {
    //     dispatch(defaultColumn());
    // };
    // const handleMenu = () => {
    //     dispatch(setAnchorMenu());
    // };


    const handleMenuClick = (event) => {
        // dispatch(setAnchorMenu(true))
        dispatch(setAnchorMenu(event.currentTarget))
    };
    const handleMenuClose = () => {
        // dispatch(setAnchorMenu(false))
        dispatch(setAnchorMenu(null))
    };

    const handleMassDel = () => {
        setLoading(true);
        Promise.all(
            Object.values(selectedRows).map(async (item, key) => {
                try {
                    const response = await $authHost.delete(`/form-budget-submission/row-delete?id=${Number(item.id)}`)
                    if (response.status === 200) {
                        const newRow = { ...item, status: 'Удалено' }
                        return newRow;
                    }
                } catch (e) {
                }
            })
        ).then(data_array => {
            if (userIsAdmin) {
                const newDataSource = myDataSource.map((row) => {
                    const newRow = data_array.find((changed) => changed.id === row.id);
                    return newRow ? newRow : row;
                })
                setMyDataSource(newDataSource)
                setDefaultDataSource(newDataSource)
            } else {
                const newDataSource = [...myDataSource.filter(el => !data_array.map((item) => item.id).includes(el.id))]
                setMyDataSource(newDataSource)
                setDefaultDataSource(newDataSource)
                // dispatch(selectRows({}))
            }
            dispatch(selectRows([]));
            setLoading(false);
        });
    }
    const handleMassCheck = (status) => {
        setLoading(true);
        Promise.all(
            Object.values(selectedRows).map(async (item, key) => {
                try {
                    const response = await $authHost.patch(`/form-budget-submission/row-approval?approval=${status}&id=${Number(item.id)}`);
                    if (response.status === 200) {
                        const newRow = { ...item, status: status }
                        return newRow;
                    }
                } catch (e) {
                }
            })
        ).then(newTableDataArr => {
            const newDataSource = myDataSource.map((row) => {
                const newRow = newTableDataArr.find((changed) => changed.id === row.id);
                return newRow ? newRow : row;
            })
            setMyDataSource(newDataSource)
            setDefaultDataSource(newDataSource)
        });
        dispatch(selectRows([]));
        setLoading(false);
    }

    const changeFilter = (event) => {
        if (event.target.checked) {

            dispatch(setDelFilter(true));
            setMyDataSource(defaultDataSource)
            dispatch(setActualPage(1));
        } else {

            dispatch(setDelFilter(false));
            setMyDataSource(prevData => prevData.filter(obj => obj.status !== 'Удалено'))
            dispatch(setActualPage(1));
        }
    }

    // Работа с кастомной пагинацией
    function updateSkip(value) {
        let actualPage = parseInt(value);
        dispatch(setActualPage(actualPage));
    }
    const updateLimit = (event) => {
        let amount = event.target.value;
        dispatch(setLimit(amount));
        // dispatch(setActualPage(1));
    }
    const ToFirstPage = () => {
        dispatch(setActualPage(1));
    }
    const ToLastPage = () => {
        dispatch(setActualPage(pages));
    }
    const ToNextPage = (event) => {
        const change = skip + event
        if (change >= 1 && change <= pages) {
            dispatch(setActualPage(change));
        }
    }
    useEffect(() => {
        dispatch(setRowsPages(myDataSource.length));
    }, [myDataSource]);

    useEffect(() => {
    }, [computedData]);

    useEffect(() => {
        const filteredData = defaultDataSource.filter(
            item => String(item.name).toLowerCase().indexOf(String(filterValue).toLowerCase()) !== -1
        );
        setComputedData(filteredData);
    }, [defaultDataSource, filterValue]);

    useEffect(() => {
        loadData();
    }, []);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <CssBaseline />
            <NavBar />
            <Grid xs={12} sx={{ pt: '6%', px: 2 }} container
                direction="row"
                justifyContent="space-between"
                alignItems="center">
                <Grid item>
                    <Typography variant="h4" sx={{}}>
                        Форма подачи бюджета
                    </Typography>
                </Grid>
                <Grid item>
                    <SearchComponent defaultDataSource={defaultDataSource} p_setMyDataSource={setMyDataSource}
                        gridRef={gridRef} />
                </Grid>
            </Grid>

            <Grid sx={{ px: 2 }}>
                <Paper sx={{ py: 1, px: 2, mt: 2, mb: 2, width: '100%' }}>
                    <Grid xs={12} container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <div>
                            <Button onClick={handleClick}
                                aria-haspopup="true"
                                variant="text"
                                startIcon={<ViewColumnIcon />}
                                sx={{ mr: 3 }}>колонки</Button>
                            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} style={{ width: '50%' }}>
                                <Grid container columns={{ xs: 3 }}>
                                    {menuColumns.map((column, key) => {
                                        // {columns.map((column, key) => {
                                        if (!userIsAdmin && adminColumnId.includes(column.name)) {
                                            return null;
                                        }
                                        return (
                                            <Grid item xs={1} key={key}>
                                                <MenuItem key={key}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                id={column.name}
                                                                name={column.name}
                                                                checked={showColumns.includes(column.name)}
                                                                onChange={(e) => handleColumnToggle(e, column)}
                                                            />
                                                        }
                                                        label={column.header}
                                                    />
                                                </MenuItem>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Menu>
                            {
                                filter ?
                                    <Button name="filter" variant="text" sx={{ mr: 3 }}
                                        onClick={handleChangeCheck} startIcon={<FilterAltOffIcon />}>
                                        спрятать фильтры
                                    </Button>
                                    :
                                    <Button name="filter" variant="text" sx={{ mr: 3 }}
                                        onClick={handleChangeCheck} startIcon={<FilterAltIcon />}>
                                        показать фильтры
                                    </Button>
                            }

                            {userIsAdmin &&
                                <FormControlLabel
                                    value="end"
                                    size="small"
                                    control={<Switch />}
                                    label="Показать удаленные"
                                    labelPlacement="end"
                                    checked={deleteFilter}
                                    // defaultChecked={deleteFilter || false}
                                    onChange={changeFilter}
                                    sx={{ mr: 2 }}
                                />
                            }

                        </div>
                        <div>
                            {Object.keys(selectedRows).length >= 1 &&
                                <>
                                    {userIsAdmin ?
                                        <>
                                            <Button onClick={() => handleMassCheck('Согласовано')}
                                                startIcon={<CheckCircleOutlineIcon />}
                                                // variant="outlined"
                                                variant="contained"
                                                disableElevation
                                                color="company_check"
                                                sx={{ mr: 2 }}
                                                size="small">
                                                Согласовать строки
                                            </Button>
                                            <Button onClick={() => handleMassCheck('Не согласовано')} startIcon={<BlockIcon />}
                                                // variant="outlined"
                                                disableElevation
                                                variant="contained"
                                                color="company_block"
                                                sx={{ mr: 2 }}
                                                size="small">
                                                Не согласовать строки
                                            </Button>
                                        </>
                                        :
                                        <></>
                                    }
                                    <Button onClick={handleMassDel} startIcon={<DeleteIcon />}
                                        // variant="outlined"                                                  variant="contained"
                                        variant="contained"

                                        disableElevation
                                        color="company_delete"
                                        size="small">
                                        Удалить строки
                                    </Button>
                                </>
                            }

                        </div>
                    </Grid>
                </Paper>
            </Grid >

            <Grid sx={{ px: 2 }}>
                <Paper sx={{ py: 1, px: 2, mt: 2, mb: 2, width: '100%' }}>
                    <Grid xs={12} container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={6}>
                            <FormAddRowComponent rows={Object.keys(selectedRows).length}
                                setParents_datasource={setMyDataSource}
                                p_setDefaultDataSource={setDefaultDataSource}
                                p_defaultDataSource={defaultDataSource}
                                Parents_datasource={myDataSource} setLoading={setLoading} />
                            {Object.keys(selectedRows).length >= 1 &&
                                <Button onClick={() => { dispatch(selectRows([])) }} startIcon={<ClearIcon />} variant="contained"
                                    // color="inherit"
                                    size="small">
                                    сбросить выбор
                                </Button>
                            }
                        </Grid>
                        <Grid item xs={6}
                            style={{
                                display: 'flex', alignItems: 'center',
                                alignContent: 'flex-end', justifyContent: 'flex-end'
                            }} >
                            <IconButton onClick={ToFirstPage} size="small">
                                <FirstPageIcon />
                            </IconButton>
                            <IconButton onClick={() => ToNextPage(-1)} size="small">
                                <NavigateBeforeIcon />
                            </IconButton>

                            <PagesCountsComponent
                                value={skip}
                                onChange={updateSkip}
                                pages={pages}
                            />
                            <Typography variant="subtitle1">
                                из {pages}
                            </Typography>
                            <IconButton onClick={() => ToNextPage(1)} size="small">
                                <NavigateNextIcon />
                            </IconButton>
                            <IconButton onClick={ToLastPage} size="small">
                                <LastPageIcon />
                            </IconButton>
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <Typography variant="subtitle1" sx={{ mx: 1 }}>
                                Результатов на странице:
                            </Typography>
                            <FormControl size="small" variant="standard">
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={limit}
                                    onChange={updateLimit}
                                >
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={30}>30</MenuItem>
                                </Select>
                            </FormControl>
                            <Typography variant="subtitle1" sx={{ ml: 1 }}>
                                (строки {skip_count + 1}-{(skip_count + limit) < rows ? (skip_count + limit) : rows} из {rows})
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <ReactDataGrid
                id="grid"
                idProperty="id"
                style={gridStyle}
                showZebraRows={false}

                rows

                columns={visibleColumns}
                groups={groups}
                handle={setGridRef}
                onReady={setDataGridRef}
                defaultSortInfo={{ name: 'id', dir: -1 }}

                dataSource={myDataSource}
                loading={loading}

                showColumnMenuTool={false}


                enableColumnFilterContextMenu={false}
                enableFiltering={filter}
                defaultFilterValue={defaultFilterValue}
                onFilterValueChange={onFilterValueChange}
                filterCellWrapperStyle={filterCellWrapperStyle}

                editable={true}
                onEditComplete={onEditComplete}
                autoFocusOnEditEscape={true}
                // onEditStart={onEditStart}

                pagination="local"
                // defaultLimit={10}
                // pageSizes={[2, 5, 10, 20]}
                renderPaginationToolbar={() => null}
                limit={limit}
                skip={skip_count}

                checkboxColumn={checkboxColumn}
                selected={selectedRows}
                onSelectionChange={onSelectionChange}
                checkboxOnlyRowSelect={true}

                hide
            />
        </>
    );
}

export default BudgetsAdvancesPage;

