import React from 'react';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {TextField} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import dayjs from "dayjs";
import {$authHost} from "../../http";
import {baseUrl} from "../baseRoute";
import {useDispatch, useSelector} from "react-redux";
import {addTask, UpdateStatus} from "../../reducers/uuidReducer";
import ProcessComponent from "../ProcessComponents/ProcessComponent";
import CircularProgress from "@mui/material/CircularProgress";
import {EventSourcePolyfill} from "event-source-polyfill";

const Project_budjet = () => {

    const dispatch = useDispatch();
    const [errorOrg, setErrorOrg] = React.useState(false);
    const [errorPer, setErrorPer] = React.useState(false);

    const token = useSelector(state => state.authReducer.token);

    const [dateThree, setDateThree] = React.useState(dayjs("04.05.2023"));
    const [selectNumberThree, setSelectNumberThree] = React.useState(null);
    const [numberThree, setNumberThree] = React.useState([]);
    const [periods, setPeriods] = React.useState([]);
    const [selectPeriod, setSelectPeriod] = React.useState(null);

    //стейты для асинхронного селекта
    const [open, setOpen] = React.useState(false);
    const [openAutocompletePer, setOpenAutocompletePer] = React.useState(false);
    const loading = open && numberThree.length === 0;
    const loadingPer = openAutocompletePer;
    

    const newTask = (payload) => {
        dispatch(addTask(payload));
    }

    const updateStatusTask = (payload) => {
        dispatch(UpdateStatus(payload));
    }

    const handleThreeClick = async () =>{
        if(!selectNumberThree && !selectPeriod){
            setErrorOrg(true);
            setErrorPer(true);
        } else if(!selectNumberThree && selectPeriod !== null){
            setErrorOrg(true);
            setErrorPer(false);
        } else if(selectNumberThree !== null && !selectPeriod){
            setErrorOrg(false);
            setErrorPer(true);
        }else{
            setErrorPer(false);
            setErrorOrg(false);
            var formatDate = dateThree.format('DD.MM.YYYY');
            try {
                await $authHost.get(`/project-budget?date=${formatDate}&organization_name=${numberThree}`)
                    .then(response => {
                        StatusTask(response.data, 3);
                    });
            } catch (e) {
            } finally {
            }
        }

    }

    function StatusTask(uuid_local, application){

        newTask({uuid: uuid_local, status: "PENDING", application_id: application, progress: 0});
        var EventSource = EventSourcePolyfill;

        const evtSource = new EventSource(`${baseUrl}task-status-stream?task_id=${uuid_local}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
        evtSource.addEventListener("update", function (event) {
            var _sendData = JSON.parse(event.data);
            updateStatusTask({task_id: uuid_local, status: _sendData.status, application_id: application, progress: _sendData.progress})
        });
        evtSource.addEventListener("end", function (event) {
            updateStatusTask({task_id: uuid_local, status: "FINISHED", application_id: application, progress: 100})
            evtSource.close();
        });
        evtSource.addEventListener("failed", function (event) {
            updateStatusTask({task_id: uuid_local, status: "FAILURE", application_id: application, progress: 0})
            evtSource.close();
        });

    }

    const search = async (x) => {
        try {
            await $authHost.get(`/form-budget-submission/combox/location?location=${x}&object_code=${[]}`).then(res => {
                const managers = res.data;
                setNumberThree(managers);
            })
        } catch (e) {
        }
    }

    React.useEffect(() => {
        let active = true;
        if (!loading) {
            return undefined;
        }
        (async () => {
            if (active) {
                await $authHost.get(`/form-budget-submission/combox/location?location=${[]}&object_code=${[]}`).then(res => {
                    const managers = res.data;
                    setNumberThree(managers);
                })
            }
        })();
        return () => {
            active = false;
        };
    }, [loading]);

    React.useEffect(() => {
        if (!open) {
            setNumberThree([]);
        }
    }, [open]);

    React.useEffect(() => {
        let active = true;
        if (!loadingPer) {
            return undefined;
        }
        (async () => {
            if (active) {
                try {
                    await $authHost.get(`/form-budget-submission/combox/reporting-period`).then(res => {
                        const data = res.data;
                        setPeriods(Object.values(data));
                    });
                } catch (e) {
                }
            }
        })();
        return () => {
            active = false;
        };
    }, [loadingPer]);

    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom sx={{mb:1}}>
                    Бюджет по проектам
                </Typography>
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <Grid item xs={4}>
                    <Autocomplete
                        value={selectPeriod}
                        open={openAutocompletePer}
                        onOpen={() => {
                            setOpenAutocompletePer(true);
                        }}
                        onClose={() => {
                            setOpenAutocompletePer(false);
                        }}
                        name="period"
                        id="period"
                        options={periods}
                    
                        isOptionEqualToValue={(option, value) => option === value}
                        getOptionLabel={(option) => option}
                        loading={loadingPer}
                        noOptionsText='Нет доступных вариантов'
                        onChange={(event, newValue) => {
                            setSelectPeriod(newValue);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Отчетный период"
                                name="reporting_period"
                                error={errorPer}
                                sx={{width: '90%'}}
                                helperText={errorPer ? "Выберите период" : false}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            views={['year']['month']}
                            label="Дата"
                            value={dateThree}
                            sx={{width: '90%'}}
                            onChange={(newValue) => {
                                setDateThree(newValue);
                                setSelectNumberThree('')
                            }}
                            renderInput={(params) => <TextField sx={{width: '90%'}} {...params} />}
                        />
                    </LocalizationProvider> */}
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        defaultValue={selectNumberThree}
                        id="combo-box-demo"
                        open={open}
                        onOpen={() => {
                            setOpen(true);
                        }}
                        onClose={() => {
                            setOpen(false);
                        }}
                        options={numberThree}
                        isOptionEqualToValue={(option, value) => option === value}
                        getOptionLabel={(option) => option}
                        loading={loading}
                        noOptionsText='Нет доступных вариантов'
                        onChange={(event, newValue) => {
                            setSelectNumberThree(newValue);
                        }}
                        onInputChange={(event, newInputValue) => search(newInputValue)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Локация"
                                error={errorOrg}
                                sx={{width: '90%'}}
                                helperText={errorOrg ? "Выберите локацию" : false}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{mt:2}}
            >


                <Grid item xs={4}>
                    <LoadingButton
                        disabled={false}
                        sx={{width: '90%'}}
                        onClick={handleThreeClick}
                        endIcon={<SendIcon/>}
                        loadingPosition="end"
                        variant="contained"
                        color="company_gray"
                    >
                        Выгрузить
                    </LoadingButton>
                </Grid>
                <Grid item xs={12} id="3_process">
                    <ProcessComponent application_id={3}/>
                </Grid>



            </Grid>


        </>
    );
};

export default Project_budjet;