import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {IconButton, InputAdornment} from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import {useAuth} from "../hook/useAuth";
import {$host} from '../http';
import {useSelector} from "react-redux";


export default function LoginPage() {
    const [showPassword, setShowPassword] = React.useState(() => {
        return false
    });
    const [errorEmail, setErrorEmail] = React.useState(() => {
        return false
    });
    const [errorPassword, setErrorPassword] = React.useState(() => {
        return false
    });
    const {signIn} = useAuth();

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const token = useSelector(state => state.authReducer.token)


    // const handleZeroClick = async () =>{
    //     setLoadingZero(true);
    //     var formatDate = dateZero.format('YYYY')
    //     // await new Promise(r => setTimeout(r, 1000));
    //     // await Progress_alert();
    //     // await exportToCSV("Отчет");
    //     // console.log(formatDate);
    //     // setNumberZero("numberZero");
    //     // formatDate = "19.07.2022";
    //     try {
    //         const data = await $host.get(`/requests-for-payment?date=${formatDate}&application_number=${numberZero}`)
    //             .then(response => {
    //                 console.log(response.data);
    //                 // var request_id = response.data;
    //                 Progress_alert(response.data);
    //             });
    //     } catch (e) {
    //     } finally {

    //     }
    // }

    const navigate = useNavigate();

    function validate() {
        // const regEx_user = (str) => /^[0-9A-Z_.@]{4,64}$/i.test(str);/.+@.+\..+/i
        const regEx_user = (str) => /.+@.+\..+/i.test(str);
        const regEx_password = (str) => /^[0-9A-Z_#$*!]{8,64}$/i.test(str);

        var login = document.getElementById("email");
        var password = document.getElementById("password");


        if (!regEx_user(login.value) && !regEx_password(password.value)) {
            setErrorEmail(true)
            setErrorPassword(true)
        } else if (!regEx_user(login.value)) {
            setErrorEmail(true)
            setErrorPassword(false)
        } else if (!regEx_password(password.value)) {
            setErrorEmail(false)
            setErrorPassword(true)
        } else {
            setErrorEmail(false)
            setErrorPassword(false)
            return true;
        }
    }

    const isAdmin = (num) => {
        return num === 0;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const myValidation = validate()
        if (myValidation === true) {
            const formData = new FormData(event.currentTarget);
            try {
                await $host.get(`/login?email=${formData.get("email")}&password=${formData.get("password")}`)
                    .then(response => {

                        signIn(
                            response.data.id, //user_ID
                            response.data.DisplayName,  //full_name
                            isAdmin(response.data.Role),  //isAdmin
                            response.data.token   //token
                        );
                        navigate("/budgets_and_advances");
                    });
            } catch (e) {
                if (e.response.status === 403) {
                    document.getElementById('email').value = ''
                    document.getElementById('password').value = ''
                }
            }
        }
    };

    useEffect(() => {
        if (!!token) {
            navigate("/budgets_and_advances")
        }
    }, []);


    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
                sx={{
                    marginTop: 25,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <img width="400" height="auto" src="images/logo_black.png"/>
                <Typography component="h1" variant="h5" sx={{color: '#57555a', mt: 2}}>
                    Авторизация
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1, width: 400}}>
                    <TextField
                        error={errorEmail}
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        type="email"
                        label="Логин"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        color='company_gray'
                        helperText={errorEmail ? "Некорректный ввод" : false}
                    />
                    <TextField
                        error={errorPassword}
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Пароль"
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        autoComplete="current-password"
                        color='company_gray'
                        helperText={errorPassword ? "Некорректный ввод" : false}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff/> : <Visibility/>}
                                    </IconButton>
                                </InputAdornment>,
                        }}
                    />
                    {/* <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        /> */}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                        color="company_yellow"
                    >
                        Войти
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}