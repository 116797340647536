import React, {useEffect, useState} from 'react';
import {$authHost} from "../../http";
import {Autocomplete} from "@mui/lab";
import {TextField} from "@mui/material";
import Paper from "@mui/material/Paper";

const AutocompleteObjectCode = (props) => {
    const [openAutocomplete, setOpenAutocomplete] = React.useState(false);
    const loading = openAutocomplete;

    const [options, setOptions] = useState([]);

    const search = async (x) => {
        try {
                await $authHost.get(`/form-budget-submission/combox/object-code?object_code=${x}&location=${props.pair_value ? props.pair_value : []}`).then(res => {
                    const data = res.data;
                    var SearchData = [];
                    data.forEach(el=> {
                        SearchData.push(el.Kod);
                    })
                    setOptions(SearchData);
                });

        } catch (e) {
        }
    }

    useEffect(() => {
        let active = true;
        if (!loading) {
            return undefined;
        }
        (async () => {
            if (active) {
                try {
                    await $authHost.get(`/form-budget-submission/combox/object-code?object_code=${[]}&location=${props.pair_value ? props.pair_value : []}`).then(res => {
                        const data = res.data;
                        var SearchData = [];
                        data.forEach(el=> {
                            SearchData.push(el.Kod);
                        })
                        setOptions(SearchData);
                    });
                } catch (e) {
                }
            }
        })();
        return () => {
            active = false;
        };
    }, [loading]);


    useEffect( () =>{
        if (!openAutocomplete) {
            setOptions([]);
        }
    }, [openAutocomplete])


    return (
        <Autocomplete
            value={props.value}
            size="small"
            open={openAutocomplete}
            onOpen={() => {
                setOpenAutocomplete(true);
            }}
            onClose={() => {
                setOpenAutocomplete(false);
            }}
            name="organization"
            id="organization"
            options={options}
            sx={{ width: '100%', minHeight: '32px', maxHeight: '32px', paddingTop: '4px'}}
            isOptionEqualToValue={(option, value) => option === value}
            getOptionLabel={(option) => option}
            loading={loading}
            onBlur={()=>{
                props.onBlur()}}
            noOptionsText='Нет доступных вариантов'
            onChange={(event, newValue) => {
                props.onChange(newValue);
            }}
            PaperComponent={({ children }) => (
                <Paper style={{padding: '0', width: 'max-content' }}>{children}</Paper>
            )}
            onInputChange={(event, newInputValue) => search(newInputValue)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name="label"
                    size="small"
                    variant="standard"
                    InputProps={{
                        sx:{color: '#555e68', fontSize: '14px'},
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
            renderOption={(props, option) => (
                <li {...props} style={{color: '#555e68', fontSize: '14px',
                    margin: '1px',
                }}>{option}</li>
            )}
        />
    );
};

export default AutocompleteObjectCode;