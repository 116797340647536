import {LOG_OUT} from "./authReducer";

const defaultState = {
    tasks: []
}

export const ADD_TASK = "ADD_TASK"
export const KILL_TASK = "KILL_TASK"
export const UPDATE_STATUS = "UPDATE_STATUS"

export default function uuidReducer(state = defaultState, action) {
    switch (action.type) {
        case ADD_TASK:
            return {...state, tasks: [...state.tasks, action.payload]}
        case KILL_TASK:
            return {...state, tasks: state.tasks.filter(task => task.uuid !== action.payload)}
        case UPDATE_STATUS:
            return {
                ...state,
                tasks: state.tasks.map(
                    task => task.uuid === action.payload.task_id
                        ? {
                            ...task,
                            progress: action.payload.progress,
                            status: action.payload.status
                        }
                        : task
                ),
            }
        case LOG_OUT:
            return state = defaultState;
        default:
            return state
    }
}

export const addTask = payload => ({ type: ADD_TASK, payload }) // actionCreator
export const UpdateStatus = payload => ({ type: UPDATE_STATUS, payload }) // actionCreator
export const KillTask = payload => ({ type: KILL_TASK, payload }) // actionCreator
