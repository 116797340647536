import React from "react";
import {Route, Routes} from 'react-router-dom';
import LoginPage from "./pages/loginPage";
import EmployerPage from "./pages/employerPage";
import EmployerAdminPage from "./pages/employerAdminPage";
import AdminPage from "./pages/adminPage";
import NotFoundPage from "./pages/NotFoundPage";
import {AuthProvider} from "./hoc/authProvider";
import {LoadProvider} from "./hoc/loadProvider";
import {RequareAuth} from "./hoc/requreAuth";
import ExcelReportPage from "./pages/excel_reportPage";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import BudgetsAdvancesPage from "./pages/BudgetsAdvancesPage";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import UserManagementPage from "./pages/UserManagementPage";
import {AdminAuth} from "./hoc/adminAuth";


function App() {

    const theme = createTheme({
        palette: {
            company_gray: {
                main: '#1976d2',
                contrastText: '#fff'
            },
            company_yellow: {
                main: '#000000',
                contrastText: '#ffffff'
            },
            navigation_light: {
                main: '#ffffff',
                contrastText: '#000000'
            },
            navigation_yellow: {
                main: '#171717',
                contrastText: '#ffffff'
            },
            company_check: {
                main: '#ffffff',
                contrastText: '#1b5e20'
            },
            company_block: {
                main: '#ffffff',
                contrastText: '#c62828'
            },
            company_delete: {
                main: '#ffffff',
                contrastText: '#555555'
            },
            text_gray: {
                main: '#555e68',
                contrastText: '#555e68'
            }

        },
        typography: {
            subtitle1: {
                fontSize: 14,
                color: '#555555'
            },
        }

    })
    return (
        <AuthProvider>
            <LoadProvider>
                <div className="container">
                    <ThemeProvider theme={theme}>
                        <Routes>
                            <Route path='/' element={<LoginPage/>}/>
                            <Route path='/employer' element={
                                <RequareAuth>
                                    <EmployerPage/>
                                </RequareAuth>
                            }/>
                            <Route path='/excel_report' element={
                                <RequareAuth>
                                    {/*<TestPage/>*/}
                                    <ExcelReportPage/>
                                </RequareAuth>
                            }/>
                            <Route path='/budgets_and_advances' element={
                                <RequareAuth>
                                    <BudgetsAdvancesPage/> {/* страница web-формы “Форма подачи бюджета и авансы СМР” */}
                                </RequareAuth>
                            }/>
                            <Route path='/user_management' element={
                                <AdminAuth>
                                    <UserManagementPage/> {/* Admin - страница управления пользователями */}
                                </AdminAuth>
                            }/>
                            <Route path='/admin_employer' element={
                                <AdminAuth>
                                    <EmployerAdminPage/> {/* Admin - */}
                                </AdminAuth>
                            }/>
                            <Route path='/admin' element={
                                <AdminAuth>
                                    <AdminPage/> {/* Admin - */}
                                </AdminAuth>
                            }/>
                            <Route path='*' element={<NotFoundPage/>}/>
                        </Routes>
                    </ThemeProvider>
                </div>
            </LoadProvider>
        </AuthProvider>

    );
}

export default App;
