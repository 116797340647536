import React from 'react';
import { NumericFormat } from 'react-number-format';
import { TextField } from '@mui/material';

const NumberEditorComponent = (props) => {

    const materialUITextFieldProps = {
        variant: "standard",
        size: 'small',
        width: '100%'
    };

    return (
        <NumericFormat
            value={props.value}
            onValueChange={(values) => {
                props.onChange(values.floatValue);
            }}
            onBlur={()=>{props.onBlur()}}
            decimalSeparator={"."}
            decimalScale={2}
            allowNegative={props.negative}
            customInput={TextField}
            {...materialUITextFieldProps}
            InputProps={{
                sx:{color: '#555e68', fontSize: '14px', width: '100%'}}}
        />
    )
}

export default NumberEditorComponent;