import React, { useEffect, useState } from 'react';
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { InputAdornment, Popover, TextField, Stack } from "@mui/material";
import { Autocomplete } from "@mui/lab";
import Grid from "@mui/material/Grid";
import { Item } from "@inovua/reactdatagrid-community/packages/Flex";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { $authHost } from "../../http";
import { useAuth } from "../../hook/useAuth";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from "react-redux";
import { setErorValid } from "../../reducers/budgetsAdvancesTableReducer";
import {NumericFormat} from "react-number-format";

const FormAddRowComponent = ({ setParents_datasource, Parents_datasource, p_setDefaultDataSource, p_defaultDataSource, setLoading, rows }) => {

    const { user } = useAuth();
    const dispatch = useDispatch()
    const userIsAdmin = useSelector(state => state.authReducer.userIsAdmin)
    //тех стейты
    const [openAutocompleteOrg, setOpenAutocompleteOrg] = React.useState(false);
    const [openAutocompleteLoc, setOpenAutocompleteLoc] = React.useState(false);
    const [openAutocompleteCode, setOpenAutocompleteCode] = React.useState(false);
    const [openAutocompleteSub, setOpenAutocompleteSub] = React.useState(false);
    const [openAutocompleteContract, setOpenAutocompleteContract] = React.useState(false);
    const [openAutocompleteContractGP, setOpenAutocompleteContractGP] = React.useState(false);
    const [openAutocompleteContractDC, setOpenAutocompleteContractDC] = React.useState(false);
    const [openAutocompleteWorks, setOpenAutocompleteWorks] = React.useState(false);
    const [openAutocompletePer, setOpenAutocompletePer] = React.useState(false);

    const loadingOrg = openAutocompleteOrg;
    const loadingLoc = openAutocompleteLoc;
    const loadingCode = openAutocompleteCode;
    const loadingSub = openAutocompleteSub;
    const loadingContract = openAutocompleteContract;
    const loadingContractGP = openAutocompleteContractGP;
    const loadingContractDC = openAutocompleteContractDC;
    const loadingWork = openAutocompleteWorks;
    const loadingPer = openAutocompletePer;

    //стейты данных
    const [cases, setCases] = useState(["Выполнение", "Аванс"]);
    const [selectCases, setSelectCases] = useState(null);

    const [organizations, setOrganizations] = useState([]);
    const [selectOrganization, setSelectOrganization] = useState(null);

    const [locations, setLocations] = useState([]);
    const [selectLocation, setSelectLocation] = useState(null);

    const [CodeOdjects, setCodeOdjects] = useState([]);
    const [selectCodeOdject, setSelectCodeOdject] = useState(null);

    const [SubContractors, setSubContractors] = useState([]);
    const [selectSubContractor, setSelectSubContractor] = useState(null);

    const [ContractNumbers, setContractNumbers] = useState([]);
    const [selectContractNumber, setSelectContractNumber] = useState(null);

    const [ContractsGP, setContractsGP] = useState([]);
    const [selectContractGP, setSelectContractGP] = useState(null);

    const [ContractsDC, setContractsDC] = useState([]);
    const [selectContractDC, setSelectContractDC] = useState(null);

    const [Works, setWorks] = useState([]);
    const [selectWork, setSelectWork] = useState(null);

    const [periods, setPeriods] = useState([]);
    const [selectPeriod, setSelectPeriod] = useState(null);

    const [anchorEl, setAnchorEl] = React.useState(null);//херня
    const ErrorValid = useSelector(state => state.budgetsAdvancesTableReducer.errorValid)

    const search = async (field, x) => {
        try {
            switch (field) {
                case "org":
                    await $authHost.get(`/form-budget-submission/combox/organization?organization=${x}&contract_number_gp=${selectContractGP ? selectContractGP : []}}&dc_number_contract_gp=${selectContractDC ? selectContractDC : []}`).then(res => {
                        const data = res.data;
                        setOrganizations(data);
                    });
                    break;
                case "loc":
                    // await $authHost.get(`/form-budget-submission/combox/location?location=${x}&object_code=${selectCodeOdject ? selectCodeOdject.Kod : []}`).then(res => {
                    await $authHost.get(`/form-budget-submission/combox/location?location=${x}`).then(res => {
                        const data = res.data;
                        setLocations(data);
                    });
                    break;
                case "code":
                    await $authHost.get(`/form-budget-submission/combox/object-code?object_code=${x}&location=${selectLocation ? selectLocation : []}`).then(res => {
                        const data = res.data;
                        setCodeOdjects(data);
                    });
                    break;
                case "sub":
                    await $authHost.get(`/form-budget-submission/combox/subcontractor?subcontractor=${x}&contract_number=${selectContractNumber ? selectContractNumber : []}`).then(res => {
                        const data = res.data;
                        setSubContractors(data);
                    });
                    break;
                case "contract":
                    await $authHost.get(`/form-budget-submission/combox/contract-number?contract_number=${x}&subcontractor=${selectSubContractor ? selectSubContractor : []}`).then(res => {
                        const data = res.data;
                        setContractNumbers(data);
                    });
                    break;
                case "gp":
                    await $authHost.get(`/form-budget-submission/combox/contract-number-gp?contract_number_gp=${x}&subcontractor=${selectSubContractor ? selectSubContractor : []}`).then(res => {
                        const data = res.data;
                        setContractsGP(data);
                    });
                    break;
                case "dc":
                    await $authHost.get(`/form-budget-submission/combox/dc-contract-number-gp?dc_number_contract_gp=${x}&subcontractor=${selectSubContractor ? selectSubContractor : []}&contract_number_gp=${selectContractGP ? selectContractGP : []}`).then(res => {
                        const data = res.data;
                        setContractsDC(data);
                    });
                    break;
                case "work":
                    await $authHost.get(`/form-budget-submission/combox/work?work=${x}&organization=${selectOrganization ? selectOrganization : []}`).then(res => {
                        const data = res.data;
                        setWorks(data);
                    });
                    break;
                default: break;

            }
        } catch (e) {
        }
    }


    const handleAddRow = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const handleClearRowForm = (event) => {
        setSelectOrganization(null)
        setSelectLocation(null);
        setSelectCodeOdject(null);
        setSelectSubContractor(null);
        setSelectContractNumber(null);
        setSelectContractGP(null);
        setSelectContractDC(null);
        setSelectWork(null);
        setSelectPeriod(null);
        setSelectCases(null)
        document.getElementById('note').value = ''
        document.getElementById('amount_proposed').value = ''
        setErrorCase(false);
        setErrorOrg(false);
        setErrorPeriod(false);
        setErrorLoc(false);
        setErrorCode(false);
        setErrorSub(false);
        setErrorContract(false);
        setErrorGP(false);
        setErrorDC(false);
        setErrorWork(false);
        setErrorSumm(false);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const updateState = (new_row) => {
        //оставили два варианта взаимодействия с массивом, второе введено изза сортировки id в таблице
        Parents_datasource.unshift(new_row);
        setParents_datasource([...Parents_datasource]);
        // setParents_datasource([...Parents_datasource, new_row]);
        p_setDefaultDataSource([...Parents_datasource]);
        // p_setDefaultDataSource([...p_defaultDataSource, new_row]);
    }

    const [errorCase, setErrorCase] = React.useState(false);
    const [errorOrg, setErrorOrg] = React.useState(false);
    const [errorPeriod, setErrorPeriod] = React.useState(false);
    const [errorLoc, setErrorLoc] = React.useState(false);
    const [errorCode, setErrorCode] = React.useState(false);
    const [errorSub, setErrorSub] = React.useState(false);
    const [errorContract, setErrorContract] = React.useState(false);
    const [errorGP, setErrorGP] = React.useState(false);
    const [errorDC, setErrorDC] = React.useState(false);
    const [errorWork, setErrorWork] = React.useState(false);
    const [errorSumm, setErrorSumm] = React.useState(false);

    const validate = (data) => {
        switch (data['case']) {
            case 'Выполнение':
                data['organization'] === '' ? setErrorOrg(true) : setErrorOrg(false);
                data['location'] === '' ? setErrorLoc(true) : setErrorLoc(false);
                data['object_code'] === '' ? setErrorCode(true) : setErrorCode(false);
                data['subcontractor'] === '' ? setErrorSub(true) : setErrorSub(false);
                data['contract_number'] === '' ? setErrorContract(true) : setErrorContract(false);
                data['contract_number_gp'] === '' ? setErrorGP(true) : setErrorGP(false);
                data['dc_number_contract_gp'] === '' ? setErrorDC(true) : setErrorDC(false);
                data['work'] === '' ? setErrorWork(true) : setErrorWork(false);
                if (isNaN(parseFloat(data['amount_proposed'])) || !isFinite(data['amount_proposed'])) {
                    setErrorSumm(true);
                } else { setErrorSumm(false) }
                data['reporting_period'] === '' ? setErrorPeriod(true) : setErrorPeriod(false);
                const birds = ["note", "amount_accepted"];
                const k = Object.keys(data).filter(key => !birds.includes(key));
                return k.filter(el => data[el] === "").length <= 0;
            case 'Аванс':
                data['organization'] === '' ? setErrorOrg(true) : setErrorOrg(false);
                data['location'] === '' ? setErrorLoc(true) : setErrorLoc(false);
                data['object_code'] === '' ? setErrorCode(true) : setErrorCode(false);
                data['work'] === '' ? setErrorWork(true) : setErrorWork(false);
                if (isNaN(parseFloat(data['amount_proposed'])) || !isFinite(data['amount_proposed'])) {
                    setErrorSumm(true);
                } else { setErrorSumm(false) }
                data['reporting_period'] === '' ? setErrorPeriod(true) : setErrorPeriod(false);
                const pets = ["organization", "location", "object_code", "reporting_period", "work", "amount_proposed"];
                const k2 = Object.keys(data).filter(key => pets.includes(key));
                return k2.filter(el => data[el] === "").length <= 0;
            default:
                setErrorCase(true);
                return false;
        }
    }


    const materialUITextField = {
        variant: "outlined",
        size: 'small',
        label: 'Сумма',
        name: 'amount_proposed',
        id: 'amount_proposed',
        error: errorSumm,
        helperText: errorSumm && "Обязательное поле"
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const data_array = {
            ...Object.fromEntries(data.entries()),
            user_id: user.user_ID,
            object_code: data.get('object_code').split('/')[0],
            amount_accepted: data.get('amount_proposed'),
        };
        if (validate(data_array)) {
            // dispatch(setErorValid(false));
            // data_array["amount_proposed"] = x.toFixed(2)
            try {
                setLoading(true);
                handleAddRow();
                await $authHost.post('/form-budget-submission/row-add', data_array)
                    .then(response => {
                        const response_data = response.data;
                        if (response.status === 200) {
                            const newState = {
                                ...data_array,
                                id: response_data.id,
                                date: response_data.date,
                                status: response_data.status === 1 ? 'На согласовании' : response_data.status === 0 ? 'Ожидание' : response_data.status,
                            }
                            if (userIsAdmin) {
                                newState.executor = user.full_name
                            }
                            updateState(newState);
                            setSelectOrganization(null)
                            setSelectLocation(null);
                            setSelectCodeOdject(null);
                            setSelectSubContractor(null);
                            setSelectContractNumber(null);
                            setSelectContractGP(null);
                            setSelectContractDC(null);
                            setSelectWork(null);
                            setSelectPeriod(null);
                            setSelectCases(null)
                            document.getElementById('note').value = ''
                            document.getElementById('amount_proposed').value = ''
                        }
                    })
            } catch (e) {
            } finally {
                setLoading(false);
            }
        } else {
            // dispatch(setErorValid(true));
        }

    }

    useEffect(() => {
        setErrorCase(false);
        setErrorOrg(false);
        setErrorPeriod(false);
        setErrorLoc(false);
        setErrorCode(false);
        setErrorSub(false);
        setErrorContract(false);
        setErrorGP(false);
        setErrorDC(false);
        setErrorWork(false);
        setErrorSumm(false);
    }, [selectCases]);

    useEffect(() => {
        let active = true;
        if (!loadingWork) {
            return undefined;
        }
        (async () => {
            if (active) {
                try {
                    await $authHost.get(`/form-budget-submission/combox/work?work=${[]}&organization=${selectOrganization ? selectOrganization : []}`).then(res => {
                        const data = res.data;
                        setWorks(data);
                    });
                } catch (e) {
                }
            }
        })();
        return () => {
            active = false;
        };
    }, [loadingWork]);

    useEffect(() => {
        let active = true;
        if (!loadingContractDC) {
            return undefined;
        }
        (async () => {
            if (active) {
                try {
                    await $authHost.get(`/form-budget-submission/combox/dc-contract-number-gp?dc_number_contract_gp=${[]}&subcontractor=${selectSubContractor ? selectSubContractor : []}&contract_number_gp=${selectContractGP ? selectContractGP : []}`).then(res => {
                        const data = res.data;
                        setContractsDC(data);
                    });
                } catch (e) {
                }
            }
        })();
        return () => {
            active = false;
        };
    }, [loadingContractDC]);

    useEffect(() => {
        let active = true;
        if (!loadingContractGP) {
            return undefined;
        }
        (async () => {
            if (active) {
                try {
                    await $authHost.get(`/form-budget-submission/combox/contract-number-gp?contract_number_gp=${[]}&subcontractor=${selectSubContractor ? selectSubContractor : []}`).then(res => {
                        const data = res.data;
                        setContractsGP(data);
                    });
                } catch (e) {
                }
            }
        })();
        return () => {
            active = false;
        };
    }, [loadingContractGP]);

    useEffect(() => {
        let active = true;
        if (!loadingContract) {
            return undefined;
        }
        (async () => {
            if (active) {
                try {
                    await $authHost.get(`/form-budget-submission/combox/contract-number?contract_number=${[]}&subcontractor=${selectSubContractor ? selectSubContractor : []}`).then(res => {
                        const data = res.data;
                        setContractNumbers(data);
                    });
                } catch (e) {
                }
            }
        })();
        return () => {
            active = false;
        };
    }, [loadingContract]);

    useEffect(() => {
        let active = true;
        if (!loadingSub) {
            return undefined;
        }
        (async () => {
            if (active) {
                try {
                    await $authHost.get(`/form-budget-submission/combox/subcontractor?subcontractor=${[]}&contract_number=${selectContractNumber ? selectContractNumber : []}`).then(res => {
                        const data = res.data;
                        setSubContractors(data);
                    });
                } catch (e) {
                }
            }
        })();
        return () => {
            active = false;
        };
    }, [loadingSub]);

    useEffect(() => {
        let active = true;
        if (!loadingCode) {
            return undefined;
        }
        (async () => {
            if (active) {
                try {
                    await $authHost.get(`/form-budget-submission/combox/object-code?object_code=${[]}&location=${selectLocation ? selectLocation : []}`).then(res => {
                        const data = res.data;
                        setCodeOdjects(data);
                    });
                } catch (e) {
                }
            }
        })();
        return () => {
            active = false;
        };
    }, [loadingCode]);

    useEffect(() => {
        let active = true;
        if (!loadingLoc) {
            return undefined;
        }
        (async () => {
            if (active) {
                try {
                    await $authHost.get(`/form-budget-submission/combox/location?location=${[]}&object_code=${selectCodeOdject ? selectCodeOdject.Kod : []}`).then(res => {
                        const data = res.data;
                        setLocations(data);
                    });
                } catch (e) {
                }
            }
        })();
        return () => {
            active = false;
        };
    }, [loadingLoc]);

    useEffect(() => {
        let active = true;
        if (!loadingOrg) {
            return undefined;
        }
        (async () => {
            if (active) {
                try {
                    await $authHost.get(`/form-budget-submission/combox/organization?organization=${[]}&contract_number_gp=${selectContractGP ? selectContractGP : []}}&dc_number_contract_gp=${selectContractDC ? selectContractDC : []}`).then(res => {
                        const data = res.data;
                        setOrganizations(data);
                    });
                } catch (e) {
                }
            }
        })();
        return () => {
            active = false;
        };
    }, [loadingOrg]);

    useEffect(() => {
        let active = true;
        if (!loadingPer) {
            return undefined;
        }
        (async () => {
            if (active) {
                try {
                    await $authHost.get(`/form-budget-submission/combox/reporting-period`).then(res => {
                        const data = res.data;
                        setPeriods(Object.values(data));
                    });
                } catch (e) {
                }
            }
        })();
        return () => {
            active = false;
        };
    }, [loadingPer]);

    useEffect(() => {
        if (!openAutocompleteOrg) {
            setOrganizations([]);
        }
        if (!openAutocompleteLoc) {
            setLocations([]);
        }

        if (!openAutocompleteCode) {
            setCodeOdjects([]);
        }

        if (!openAutocompleteSub) {
            setSubContractors([]);
        }

        if (!openAutocompleteContract) {
            setContractNumbers([]);
        }

        if (!openAutocompleteContractGP) {
            setContractsGP([]);
        }

        if (!openAutocompleteContractDC) {
            setContractsDC([]);
        }

        if (!openAutocompleteWorks) {
            setWorks([]);
        }

        // if(!openAutocompletePer) {
        //     setPeriods([]);
        // }

    }, [openAutocompleteOrg, openAutocompleteLoc, openAutocompleteCode, openAutocompleteSub,
        openAutocompleteContract, openAutocompleteContractGP, openAutocompleteContractDC, openAutocompleteWorks, openAutocompletePer
    ])

    return (
        <>
            <Button onClick={handleAddRow} startIcon={<AddIcon />}
                variant="contained"
                disableElevation
                sx={{ mr: 2, mt: 1, mb: 1 }}
                disabled={rows >= 1}
                size="small"> Новая строка</Button>
            <Popover id={id} open={open} anchorEl={anchorEl} anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}>
                <Paper sx={{ width: '97vw' }} >
                    <Box sx={{ p: 2 }} component="form" onSubmit={handleSubmit} id="add_form">
                        <Grid container spacing={1} >
                            <Grid item xs={3}>
                                <Item>
                                    <Autocomplete
                                        name="case"
                                        size="small"
                                        id="case"
                                        value={selectCases}
                                        onChange={(event, newValue) => {
                                            setSelectCases(newValue);
                                        }}
                                        options={cases}
                                        sx={{ width: '100%', my: 1 }}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                name="case"
                                                error={errorCase}
                                                helperText={errorCase ? "Обязательное поле" : false}
                                                label="Сценарий" />}
                                    />
                                </Item>
                            </Grid>
                            <Grid item xs={3}>
                                <Item>
                                    <Autocomplete
                                        value={selectPeriod}
                                        size="small"
                                        open={openAutocompletePer}
                                        onOpen={() => {
                                            setOpenAutocompletePer(true);
                                        }}
                                        onClose={() => {
                                            setOpenAutocompletePer(false);
                                        }}
                                        name="period"
                                        id="period"
                                        options={periods}
                                        sx={{ width: '100%', my: 1 }}
                                        isOptionEqualToValue={(option, value) => option === value}
                                        getOptionLabel={(option) => option}
                                        loading={loadingPer}
                                        noOptionsText='Нет доступных вариантов'
                                        onChange={(event, newValue) => {
                                            setSelectPeriod(newValue);
                                        }}
                                        // onInputChange={(event, newInputValue) => search("per",newInputValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Отчетный период"
                                                name="reporting_period"
                                                error={errorPeriod}
                                                helperText={errorPeriod ? "Обязательное поле" : false}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {/*{loading ? <CircularProgress color="inherit" size={20}/> : null}*/}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                </Item>
                            </Grid>
                            <Grid item xs={6}>
                                <Item>
                                </Item>
                            </Grid>
                            <Grid item xs={3}>
                                <Item>
                                    <Autocomplete
                                        value={selectOrganization}
                                        size="small"
                                        open={openAutocompleteOrg}
                                        onOpen={() => {
                                            setOpenAutocompleteOrg(true);
                                        }}
                                        onClose={() => {
                                            setOpenAutocompleteOrg(false);
                                        }}
                                        name="organization"
                                        id="organization"
                                        options={organizations}
                                        sx={{ width: '100%', my: 1 }}
                                        isOptionEqualToValue={(option, value) => option === value}
                                        getOptionLabel={(option) => option}
                                        loading={loadingOrg}
                                        noOptionsText='Нет доступных вариантов'
                                        onChange={(event, newValue) => {
                                            setSelectOrganization(newValue);
                                        }}
                                        onInputChange={(event, newInputValue) => search("org", newInputValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Организация"
                                                name="organization"
                                                error={errorOrg}
                                                helperText={errorOrg ? "Обязательное поле" : false}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {/*{loading ? <CircularProgress color="inherit" size={20}/> : null}*/}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                </Item>
                            </Grid>
                            <Grid item xs={3}>
                                <Item>
                                    <Autocomplete
                                        size="small"
                                        id="location"
                                        name="location"
                                        sx={{ width: '100%', my: 1 }}
                                        value={selectLocation}
                                        open={openAutocompleteLoc}
                                        onOpen={() => {
                                            setOpenAutocompleteLoc(true);
                                        }}
                                        onClose={() => {
                                            setOpenAutocompleteLoc(false);
                                        }}
                                        options={locations}
                                        isOptionEqualToValue={(option, value) => option === value}
                                        getOptionLabel={(option) => option}
                                        loading={loadingLoc}
                                        noOptionsText='Нет доступных вариантов'
                                        onChange={(event, newValue) => {
                                            setSelectCodeOdject(null)
                                            setSelectLocation(newValue)
                                        }}
                                        onInputChange={(event, newInputValue) => search("loc", newInputValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Локация"
                                                name="location"
                                                error={errorLoc}
                                                helperText={errorLoc ? "Обязательное поле" : false}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {/*{loading ? <CircularProgress color="inherit" size={20}/> : null}*/}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                </Item>
                            </Grid>
                            <Grid item xs={3}>
                                <Item>
                                    <Autocomplete
                                        name="object_id"
                                        id="object_code"
                                        size="small"
                                        sx={{ width: '100%', my: 1 }}
                                        value={selectCodeOdject}
                                        open={openAutocompleteCode}
                                        onOpen={() => {
                                            setOpenAutocompleteCode(true);
                                        }}
                                        onClose={() => {
                                            setOpenAutocompleteCode(false);
                                        }}
                                        options={CodeOdjects}
                                        // isOptionEqualToValue={(option, value) => {(option.Name === value) || (option.Kod === value)}}
                                        getOptionLabel={(option) => option.Kod + ' / ' + option.Name}
                                        loading={loadingCode}
                                        noOptionsText='Нет доступных вариантов'
                                        onChange={(event, newValue) => {
                                            setSelectCodeOdject(newValue);
                                        }}
                                        onInputChange={(event, newInputValue) => {
                                            search("code", newInputValue)
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Код объекта"
                                                name="object_code"
                                                error={errorCode}
                                                helperText={errorCode ? "Обязательное поле" : false}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {/*{loading ? <CircularProgress color="inherit" size={20}/> : null}*/}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                </Item>
                            </Grid>
                            <Grid item xs={3}>
                                <Item>
                                </Item>
                            </Grid>
                            <Grid item xs={3}>
                                <Item>
                                    <Autocomplete
                                        size="small"
                                        name="counterparty"
                                        id="subcontractor"
                                        sx={{ width: '100%', my: 1 }}
                                        value={selectSubContractor}
                                        open={openAutocompleteSub}
                                        onOpen={() => {
                                            setOpenAutocompleteSub(true);
                                        }}
                                        onClose={() => {
                                            setOpenAutocompleteSub(false);
                                        }}
                                        options={SubContractors}
                                        // isOptionEqualToValue={(option, value) => {(option.Name === value) || (option.Kod === value)}}
                                        getOptionLabel={(option) => option}
                                        loading={loadingSub}
                                        noOptionsText='Нет доступных вариантов'
                                        onChange={(event, newValue) => {
                                            setSelectContractNumber(null)
                                            setSelectContractGP(null)
                                            setSelectContractDC(null)
                                            setSelectSubContractor(newValue);
                                        }}
                                        onInputChange={(event, newInputValue) => {
                                            search("sub", newInputValue)
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Контрагент/Субподрядчик"
                                                name="subcontractor"
                                                error={errorSub}
                                                helperText={errorSub ? "Обязательное поле" : false}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {/*{loading ? <CircularProgress color="inherit" size={20}/> : null}*/}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                </Item>
                            </Grid>
                            <Grid item xs={3}>
                                <Item>
                                    <Autocomplete
                                        size="small"
                                        name="contract_id"
                                        id="contract_number"
                                        sx={{ width: '100%', my: 1 }}
                                        value={selectContractNumber}
                                        open={openAutocompleteContract}
                                        onOpen={() => {
                                            setOpenAutocompleteContract(true);
                                        }}
                                        onClose={() => {
                                            setOpenAutocompleteContract(false);
                                        }}
                                        options={ContractNumbers}
                                        // isOptionEqualToValue={(option, value) => {(option.Name === value) || (option.Kod === value)}}
                                        getOptionLabel={(option) => option}
                                        loading={loadingContract}
                                        noOptionsText='Нет доступных вариантов'
                                        onChange={(event, newValue) => {
                                            setSelectContractNumber(newValue);
                                        }}
                                        onInputChange={(event, newInputValue) => {
                                            search("contract", newInputValue)
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="№ Договора"
                                                name="contract_number"
                                                error={errorContract}
                                                helperText={errorContract ? "Обязательное поле" : false}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {/*{loading ? <CircularProgress color="inherit" size={20}/> : null}*/}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                </Item>
                            </Grid>
                            <Grid item xs={3}>
                                <Item>
                                    <Autocomplete
                                        size="small"
                                        name="general_contract_id"
                                        id="contract_number_gp"
                                        sx={{ width: '100%', my: 1 }}
                                        value={selectContractGP}
                                        open={openAutocompleteContractGP}
                                        onOpen={() => {
                                            setOpenAutocompleteContractGP(true);
                                        }}
                                        onClose={() => {
                                            setOpenAutocompleteContractGP(false);
                                        }}
                                        options={ContractsGP}
                                        // isOptionEqualToValue={(option, value) => {(option.Name === value) || (option.Kod === value)}}
                                        getOptionLabel={(option) => option}
                                        loading={loadingContractGP}
                                        noOptionsText='Нет доступных вариантов'
                                        onChange={(event, newValue) => {
                                            setSelectContractGP(newValue);
                                            setSelectContractDC(null);
                                        }}
                                        onInputChange={(event, newInputValue) => {
                                            search("gp", newInputValue)
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="№ Договора ГП"
                                                name="contract_number_gp"
                                                error={errorGP}
                                                helperText={errorGP ? "Обязательное поле" : false}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {/*{loading ? <CircularProgress color="inherit" size={20}/> : null}*/}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                </Item>
                            </Grid>
                            <Grid item xs={3}>
                                <Item>
                                    <Autocomplete
                                        size="small"
                                        name="aa_general_contract_id"
                                        id="dc_number_contract_gp"
                                        sx={{ width: '100%', my: 1 }}
                                        value={selectContractDC}
                                        open={openAutocompleteContractDC}
                                        onOpen={() => {
                                            setOpenAutocompleteContractDC(true);
                                        }}
                                        onClose={() => {
                                            setOpenAutocompleteContractDC(false);
                                        }}
                                        options={ContractsDC}
                                        // isOptionEqualToValue={(option, value) => {(option.Name === value) || (option.Kod === value)}}
                                        getOptionLabel={(option) => option}
                                        loading={loadingContractDC}
                                        noOptionsText='Нет доступных вариантов'
                                        onChange={(event, newValue) => {
                                            setSelectContractDC(newValue);
                                        }}
                                        onInputChange={(event, newInputValue) => {
                                            search("dc", newInputValue)
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="№ ДС к Договору ГП"
                                                name="dc_number_contract_gp"
                                                error={errorDC}
                                                helperText={errorDC ? "Обязательное поле" : false}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {/*{loading ? <CircularProgress color="inherit" size={20}/> : null}*/}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                </Item>
                            </Grid>
                            <Grid item xs={3}>
                                <Item>
                                    <Autocomplete
                                        size="small"
                                        name="works"
                                        id="works"
                                        sx={{ width: '100%', my: 1 }}
                                        value={selectWork}
                                        open={openAutocompleteWorks}
                                        onOpen={() => {
                                            setOpenAutocompleteWorks(true);
                                        }}
                                        onClose={() => {
                                            setOpenAutocompleteWorks(false);
                                        }}
                                        options={Works}
                                        // isOptionEqualToValue={(option, value) => {(option.Name === value) || (option.Kod === value)}}
                                        getOptionLabel={(option) => option}
                                        loading={loadingWork}
                                        noOptionsText='Нет доступных вариантов'
                                        onChange={(event, newValue) => {
                                            setSelectWork(newValue);
                                        }}
                                        onInputChange={(event, newInputValue) => {
                                            search("work", newInputValue)
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Работы"
                                                name="work"
                                                error={errorWork}
                                                helperText={errorWork ? "Обязательное поле" : false}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {/*{loading ? <CircularProgress color="inherit" size={20}/> : null}*/}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />

                                    <NumericFormat
                                        name="amount_proposed"
                                        id="amount_proposed"
                                        decimalSeparator={"."}
                                        decimalScale={2}
                                        allowNegative={false}
                                        customInput={TextField}
                                        {...materialUITextField}
                                        sx={{ width: '100%', my: 1}}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">₽</InputAdornment>,
                                        }}
                                    />
                                </Item>
                            </Grid>
                            <Grid item xs={6}>
                                <Item>
                                    <TextField
                                        size="small"
                                        name="note"
                                        sx={{ width: '100%', my: 1, pb: 1 }}
                                        id="note"
                                        label="Примечание"
                                        multiline
                                        rows={3}
                                    />
                                </Item>
                            </Grid>
                            <Grid xs={12} container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="flex-start">
                                <Grid item>
                                    <IconButton aria-label="delete" onClick={handleAddRow} color="primary">
                                        <ExpandLessIcon />
                                        <Typography  >
                                            Скрыть
                                        </Typography>
                                    </IconButton>
                                </Grid>
                                <Stack direction="row" spacing={1} alignItems="center" sx={{ pl: 3 }}>
                                    <Grid item>
                                        <IconButton onClick={handleClearRowForm} color="primary">
                                            <Typography  >
                                                очистить ввод
                                            </Typography>
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" type="submit">
                                            добавить
                                        </Button>
                                    </Grid>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>


                </Paper>
            </Popover>

        </>
    );
};

export default FormAddRowComponent;