import React from 'react';

const AdminPage = () => {
    //страница редактрирования таблицы документов администратором

    return (
        <div>
            
        </div>
    );
};

export default AdminPage;