import React from 'react';
import Modal from 'react-modal';
import '../../index.css';
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../hook/useAuth";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth:"550px",
        width: "100%",
        padding: "30px",
        fontSize: "16px"
    },
    overlay:{
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        display: 'flex',
        zIndex: '10000'
    }
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)

export const TimeoutWarningModal = ({isOpen, onRequestClose}) => {

    const navigate = useNavigate();
    const { signOut } = useAuth();

    const onLogOffCall = () => {
        signOut(() => { navigate('/') });
    }


    return (
        <div>
            <Modal
                isOpen={isOpen}
                style={customStyles}

            >
                <Typography variant="h5" sx={{mb: 2}}>Время сессии подходит к концу</Typography>
                <Typography variant="subtitle1">Выход произойдет автоматически менее чем через 15 минут.</Typography>
                <Typography variant="subtitle1">Советуем переавторизоваться во избежании потери данных.</Typography>
                <br/>
                <Button variant="text" onClick={onLogOffCall}>Выйти сейчас</Button>
                <Button variant="contained" sx={{mx: 2}} onClick={onRequestClose}>Понятно</Button>
            </Modal>
        </div>
    );
}